import IResponse from '../contract/IResponse';
import StorageService from "./StorageService";
import ApiRequestService from './ApiRequestService';
import NotificationUtil from './../utils/NotificationUtil';
import UnimakeApiService from './UnimakeApiService';

export default class TrabalheConoscoService {
    private unimakeApi: UnimakeApiService;    
    private path: string = '/free/TrabalheConosco';

    constructor() {
        this.unimakeApi = new UnimakeApiService();    
    }

    /**
     * Retorna as vagas do site
     */
     public async GetVagas(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {       
                let response = await this.unimakeApi.get(`${this.path}/Vagas`);
                resolve(response);
            }
            catch(error) {
                reject(false);
            }
        });
    }

    /**
     * Realiza a autenticação para a area Trabalhe Conosco
     * @param body Corpo da requisição de login
     * @returns 
     */
    public async Authenticate(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/Login`, body);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async RecoverPassword(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/RecoverPassword`, body);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async ChangePassword(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/ChangePassword`, body);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async Create(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/Create`, body);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
import React from "react";
import LoadingOverlay from "react-loading-overlay";
import NotificationUtil from "../../../utils/NotificationUtil";
import LicencaService from '../../../services/LicencaService';
import ModalUltil from "../../../utils/ModalUtil";
import {Redirect} from "react-router-dom";
import {Button, Card, Col, Divider, Form, Input, Radio, Row, Typography} from "antd";
import IResponse from "../../../contract/IResponse";
import {ILicencaDesativarValidateResponse} from "../../../contract/licenca/ILicencaDesativarValidateResponse";
import CommonUtil from "../../../utils/CommonUtil";
import {FormInstance} from "antd/lib/form";
import Modal from "antd/lib/modal/Modal";

const {Title, Paragraph, Text} = Typography;
const { TextArea } = Input;

class UmkLicencaDesativar extends React.Component<any, any> {
    formRef = React.createRef<FormInstance>();

    private NotificationUtil: NotificationUtil;
    private ModalUltil: ModalUltil;
    private LicencaService: LicencaService;
    private CommonUtil: CommonUtil;

    state = {
        loading: false,
        redirect: false,
        redirectPage: '',
        idlicenca: 0,
        idproduto: 0,
        dadosLicenca: null,
        motivo: '',
        modalDesativar: false,
    };

    constructor(props: any) {
        super(props);

        this.NotificationUtil = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.LicencaService = new LicencaService();
        this.CommonUtil = new CommonUtil();

        this.load = this.load.bind(this);
        this.getParameters = this.getParameters.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.desativarLicenca = this.desativarLicenca.bind(this);
        this.cancelar = this.cancelar.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load(): Promise<void> {
        this.setState({loading: true});

        this.getParameters();

        this.setState({loading: false});
    }

    getParameters(): void {
        try {
            let url_string = window.location.href;
            let url = new URL(url_string);

            let _idlicenca = url.searchParams.get("idlicenca");
            let _idproduto = url.searchParams.get("idproduto");

            if (!_idlicenca || _idlicenca == '0')
                throw new Error("Licença inválida");

            if (!_idproduto || _idproduto == '0')
                throw new Error("Produto inválido");

            this.setState({
                idlicenca: _idlicenca,
                idproduto: _idproduto
            }, () => {
                this.validate();
            });


        } catch (error) {
            this.ModalUltil.Error(error.message);
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }
    }

    async validate(): Promise<void> {
        try {
            this.setState({loading: true});

            let result = await this.LicencaService.validateDesativarLicenca({
                idlicenca: this.state.idlicenca,
                idproduto: this.state.idproduto
            });
            let response: IResponse = result.data;

            if (response.success) {
                let _dadosLicenca: ILicencaDesativarValidateResponse = response.data;

                this.setState({
                    dadosLicenca: _dadosLicenca,
                });
            } else {
                throw new Error(response.message);
            }

            this.setState({loading: false});
        } catch (error) {
            this.ModalUltil.Error(error.message);
            this.setState({
                loading: false,
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }
    }

    handleSubmit = (values) => {
        this.setState({
           motivo: values.motivo,
           modalDesativar: true
        });
    };

    async desativarLicenca(): Promise<void> {
        try {
            this.setState({loading: true});

            let result = await this.LicencaService.desativarLicenca({
                idlicenca: this.state.idlicenca,
                idproduto: this.state.idproduto,
                motivo: this.state.motivo
            });
            let response: IResponse = result.data;

            if (response.success) {
                this.NotificationUtil.Success("Licença desativada com sucesso!");

                this.setState({
                    redirect: true,
                    redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
                });
            } else {
                throw new Error(response.message);
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
        }
    }

    cancelar(): void {
        this.setState({ modalDesativar: false });
    }

    render() {
        let dadosLicenca: ILicencaDesativarValidateResponse = this.state.dadosLicenca;

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage}/>;
        }

        return (
            <div>
                <Modal
                    className="modal-faturamento"
                    visible={this.state.modalDesativar}
                    title="Deseja realmente desativar esta licença?"
                    onOk={this.desativarLicenca}
                    onCancel={this.cancelar}
                    centered
                    width="45rem"
                    footer={[
                        <Button key="back" onClick={this.cancelar}>
                            Não
                        </Button>,
                        <Button
                            key="submit"
                            className="unimake-green-button"
                            type="primary"
                            loading={this.state.loading}
                            onClick={this.desativarLicenca}>
                            Sim
                        </Button>,
                    ]}>
                    <Card title="Dados da Licença">
                        <Paragraph style={{fontSize: "medium"}}>
                            <span>Número: <span style={{fontWeight: 'bold'}}>{this.state.idlicenca}</span></span>
                            <br/>
                            <span>CNPJ Licenciado: <span style={{fontWeight: 'bold'}}>{dadosLicenca?.cnpj}</span></span>
                            <br/>
                            <span>Razão Social: <span
                                style={{fontWeight: 'bold'}}>{dadosLicenca?.razao_social}</span></span> <br/>
                            <span>Validade: <span
                                style={{fontWeight: 'bold'}}>{dadosLicenca?.termino_vigencia}</span></span> <br/>
                            <span>Motivo: <span
                                style={{fontWeight: 'bold'}}>{this.state.motivo}</span></span> <br/>
                        </Paragraph>
                    </Card>
                </Modal>

                <LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
                    <Title level={4}>Desativar Licença</Title>
                    <Divider/>

                    <Card title="Dados da Licença">
                        <Paragraph style={{fontSize: "medium"}}>
                            <span>Número: <span style={{fontWeight: 'bold'}}>{this.state.idlicenca}</span></span>
                            <br/>
                            <span>CNPJ Licenciado: <span style={{fontWeight: 'bold'}}>{dadosLicenca?.cnpj}</span></span>
                            <br/>
                            <span>Razão Social: <span
                                style={{fontWeight: 'bold'}}>{dadosLicenca?.razao_social}</span></span> <br/>
                            <span>Validade: <span
                                style={{fontWeight: 'bold'}}>{dadosLicenca?.termino_vigencia}</span></span>
                        </Paragraph>
                    </Card>

                    <br/>

                    <Card title="Observações" hidden={dadosLicenca?.observacoes.length == 0}>
                        <Paragraph style={{fontSize: "medium"}}>
                            {dadosLicenca?.observacoes.map((obs) =>
                                <>
                                    <span>{this.CommonUtil.formatDate(obs.data, 'yyyy-MM-dd', 'dd/MM/yyyy')} {obs.hora.substr(0, 5)} - {obs.observacoes}</span>
                                    <br/>
                                </>
                            )}
                        </Paragraph>
                    </Card>

                    <br/>

                    <Card title="Confirmação">
                        <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                            <Row>
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item
                                        className="form-item-umk"
                                        name="motivo"
                                        rules={[
                                            { required: true, message: "Informe o motivo!" },
                                            { min: 5, message: "O Motivo deve conter no mínimo 5 caracteres" },
                                        ]}
                                        label="Informe o motivo pelo qual deseja desativar essa licença.">
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" block htmlType="submit" className="login-form-button">
                                    Desativar licença
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </LoadingOverlay>
            </div>
        );
    }
}

export default UmkLicencaDesativar;

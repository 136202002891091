import React from "react";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import IResponse from "../../contract/IResponse";

import { Redirect, Link } from "react-router-dom";

import NotificationUtil from "../../utils/NotificationUtil";
import StorageService from "../../services/StorageService";
import CookieUtil from "../../utils/CookieUtil";
import CommonUtil from "../../utils/CommonUtil";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import ModalUltil from "../../utils/ModalUtil";
import TrabalheConoscoService from "../../services/TrabalheConoscoService";
import ILogin from "./../../contract/trabalhe-conosco/ILogin";
import { MaskedInput } from "antd-mask-input";

import { cpf } from "cpf-cnpj-validator";

class UmkCurriculoLogin extends React.Component<any, any> {
	state = {
		loading: false,
		redirect: false,
		redirectPage: "",
	};

	private Common: CommonUtil;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private TrabalheConoscoService: TrabalheConoscoService;
	private storage: StorageService;
	private CookieService: CookieUtil;

	constructor(props: any) {
		super(props);

		this.Common = new CommonUtil();
		this.TrabalheConoscoService = new TrabalheConoscoService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.storage = new StorageService();
		this.CookieService = new CookieUtil();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleLogged = this.handleLogged.bind(this);
	}

	componentDidMount() {
		this.handleLogged();
	}

	async handleLogged() {
		try {
			var token = this.storage.Get("TokenUsuarioTrabalheConosco");
			var lembrar = await this.CookieService.Get("TokenUsuarioTrabalheConoscoLembrar");

			if (token == null) return;

			if (token.length == 0) return;

			if (lembrar == null) return;

			if (lembrar.length == 0) return;

			if (lembrar == "false") return;

			this.setState({
				redirect: true,
				redirectPage: `${process.env.PUBLIC_URL}/trabalhe-conosco/curriculo`,
			});
		} catch (error) {
			this.ModalUltil.Exception(error);
		}
	}

	async handleSubmit(values: ILogin) {
		try {
			let body = {
				cpf: this.Common.FormatCPF(values.cpf),
				senha: values.senha,
			};

			this.setState({ loading: true });

			let result = await this.TrabalheConoscoService.Authenticate(body);

			this.setState({ loading: false });

			let response: IResponse = result.data;

			if (response.success) {
				this.storage.Set("UsuarioTrabalheConosco", JSON.stringify(response.data.usuario));
				this.storage.Set("TokenUsuarioTrabalheConosco", response.data.token);
				this.CookieService.Set("TokenUsuarioTrabalheConosco", response.data.token);

				if (values.Lembrar) {
					this.CookieService.Set("TokenUsuarioTrabalheConoscoLembrar", true);
				}

				this.NotificationService.Success(
					(response.message = response.message ? response.message : "Acesso permitido!")
				);

				this.setState({
					redirect: true,
					redirectPage: `${process.env.PUBLIC_URL}/trabalhe-conosco/curriculo`,
				});
			} else {
				this.ModalUltil.Exception(response.message);
			}
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<div>
				<div>
					<Spin tip="Aguarde..." spinning={this.state.loading}>
						<Form name="control-ref" onFinish={this.handleSubmit} className="login-form">
							<Form.Item
								className="form-item-umk"
								name="cpf"
								rules={[
									{ required: true, message: "Informe o CPF!" },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (value === "") {
												return Promise.resolve();
											}

											if (cpf.isValid(value)) {
												return Promise.resolve();
											} else {
												return Promise.reject(new Error("Informe um CPF válido"));
											}
										},
									}),
								]}>
								<MaskedInput prefix={<UserOutlined />} placeholder="CPF" mask="000.000.000-00" />
							</Form.Item>
							<Form.Item
								className="form-item-umk"
								name="senha"
								rules={[{ required: true, message: "Informe a Senha!" }]}>
								<Input prefix={<LockOutlined />} type="password" placeholder="Senha" />
							</Form.Item>
							<Form.Item name="Lembrar" valuePropName="checked">
								<Checkbox>Lembrar</Checkbox>
							</Form.Item>
							<Form.Item>
								<Button
									block
									type="primary"
									htmlType="submit"
									shape="round"
									className="login-form-button">
									Entrar
								</Button>
							</Form.Item>
							<Link to={`${process.env.PUBLIC_URL}/trabalhe-conosco/esqueci-minha-senha`}>
								<span className="login-form-forgot">Esqueci minha senha</span>
							</Link>{" "}
							ou <Link to={`${process.env.PUBLIC_URL}/trabalhe-conosco/curriculo`}>Cadastrar</Link>
						</Form>
					</Spin>
				</div>
			</div>
		);
	}
}

export default UmkCurriculoLogin;

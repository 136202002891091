import React from "react";
import Slider from "react-slick";

import WpService from "../../../services/WordPress/WpService";
import { IWpPost } from "../../../contract/IWpPost";
import UmkBlogPost from "../unimake-blog-post/UmkBlogPost";
import { Skeleton, Typography } from "antd";
import UmkBlogRightButton from "./UmkBlogRightButton";
import UmkBlogLeftButton from "./UmkBlogLeftButton";

const { Title } = Typography;
class UmkBlogNews extends React.Component<any, any> {
	static defaultProps = {
		Count: 10,
	};

	state = {
		Load: true,
		Posts: [],
		loading: false,
	};

	componentWillMount() {
		this.GetPosts();
	}

	private async GetPosts(): Promise<any> {
		let wp = new WpService();

		this.setState({
			loading: true,
		});

		wp.GetPosts(
			"https://blog.unimake.com.br/gemini/wp-json/wp/v2/posts?page=1&per_page=" + this.props.Count + "&_embed"
		)
			.then((posts) => {
				this.setState({
					Posts: posts.data,
					Load: false,
					loading: false,
				});
			})
			.catch((err) => {
				this.setState({
					loading: false,
				});
			});
	}

	getImage(item: IWpPost) {
		if (item._embedded["wp:featuredmedia"]) {
			return item._embedded["wp:featuredmedia"][0].source_url;
		} else {
			return 0;
		}
	}

	render() {
		let posts = [];
		const settings = {
			autoplay: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 1,
			prevArrow: <UmkBlogLeftButton />,
			nextArrow: <UmkBlogRightButton />,
			responsive: [
				{
					breakpoint: 1380,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
					},
				},
			],
		};

		posts = this.state.Posts;

		return (
			<>
				<div className="slider-noticias-v3">
					<Title level={2}>Últimas Notícias</Title>
					<Skeleton loading={this.state.loading}>
						<Slider {...settings}>
							{posts.map((item: IWpPost) => (
								<UmkBlogPost
									key={Math.random()}
									Link={item.link}
									Image={this.getImage(item)}
									Title={item.title.rendered}
								/>
							))}
						</Slider>
					</Skeleton>
				</div>
			</>
		);
	}
}
export default UmkBlogNews;

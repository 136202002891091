import React from "react";
import ClienteService from "../../services/ClienteService";
import IResponse from "../../contract/IResponse";
import StorageService from "../../services/StorageService";
import {format} from "date-fns";
import LoadingOverlay from "react-loading-overlay";
import {Button, Card, Col, Empty, Row, Table} from "antd";
import ModalUltil from "./../../utils/ModalUtil";
import CommonUtil from '../../utils/CommonUtil';
import NFeService from "../../services/NFeService";

class UmkClienteCompras extends React.Component<any, any> {
    private ModalUltil: ModalUltil;
    private ClienteService: ClienteService;
    private NFeService: NFeService;
    private storage: StorageService;
    private CommonUtil: CommonUtil;

    ProdutosFormatter = (value) => {
        return (
            <p dangerouslySetInnerHTML={{__html: value}}>
            </p>
        );
    };

    OptionsFormatter = (value) => {
        return (
            <div>
                {value.numero != undefined ? (
                    <div>
                        {value.numero} de {format(new Date(value.dataemissao), "dd/MM/yyyy")}
                        <br/>
                    </div>
                ) : (
                    <div></div>
                )}
                {value.arquivo_pdf && value.arquivo_xml ? (
                    <Row>
                        <Col sm={11} md={11} xs={11}>
                            <Button block type="primary" onClick={() => {
                                this.downloadFile(value, "pdf")
                            }}>
                                PDF
                            </Button>
                        </Col>

                        <Col sm={1} md={1} xs={1}/>

                        <Col sm={11} md={11} xs={11}>
                            <Button block type="primary" onClick={() => {
                                this.downloadFile(value, "xml")
                            }}>
                                XML
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <div></div>
                )}
            </div>
        );
    };

    state = {
        datasource: [],
        loading: false,
        total: 0,
    };

    private columns = [
        {
            title: "Pedido",
            key: "idpedido",
            dataIndex: "idpedido",
            width: 100,
        },
        {
            title: "Data",
            key: "data",
            dataIndex: "data",
            width: 100,
        },
        {
            title: "Tipo",
            key: "tipo",
            dataIndex: "tipo",
            width: 80,
        },
        {
            title: "Total",
            key: "total",
            dataIndex: "total",
            width: 100,
        },
        {
            title: "Situação",
            key: "situacao",
            dataIndex: "situacao",
            width: 170,
        },
        {
            title: "Produtos",
            key: "produtos",
            render: this.ProdutosFormatter,
            dataIndex: "produtos",
        },
        {
            title: "NFe",
            key: "nfe",
            dataIndex: "nfe",
            render: this.OptionsFormatter,
            width: 160,
        },
    ];


    constructor(props: any) {
        super(props);

        this.ModalUltil = new ModalUltil(props);
        this.ClienteService = new ClienteService();
        this.NFeService = new NFeService();
        this.storage = new StorageService();
        this.CommonUtil = new CommonUtil();

        this.Load = this.Load.bind(this);
    }

    componentDidMount() {
        this.Load();
    }

    private async Load() {
        try {
            this.setState({loading: true});

            let usuario: any = JSON.parse(this.storage.Get("Usuario"));

            let result = await this.ClienteService.GetCompras(usuario.idcliente);

            this.setState({loading: false});

            let response: IResponse = result.data;
            let valid = this.ClienteService.ValidateLoginFromResponse(response);

            if (valid) {
                let result = this.resolveEntities(response.data);

                this.setState({
                    datasource: result,
                });
            }

            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Exception(error);
        }
    }

    resolveEntities(entities) {
        let total = 0;

        for (let item of entities) {
            if (item.data) item.data = format(new Date(item.data), "dd/MM/yyyy");

            if (item.produtos) {
                let produtoString = "";

                let index = 1;
                for (let produto of item.produtos) {
                    let quantidade = parseInt(produto.quantidade);

                    produtoString += `${quantidade}x - ${produto.nome}`;

                    if (produto.anuidades > 1) {
                        produtoString += ` ${produto.anuidades} anos`;
                    }

                    if (item.produtos.length > 1 && index < item.produtos.length) {
                        produtoString += '<br>';
                    }

                    index++;
                }

                item.produtos = produtoString;
            }
            total += parseFloat(item.total);

            item.total = this.CommonUtil.FormatBrl(item.total);

            item.nfe = {};
            if (item.nfe_numero) {
                item.nfe.numero = item.nfe_numero;
                item.nfe.dataemissao = item.nfe_emissao;
                item.nfe.chaveacesso = item.nfe_chaveacesso;
                item.nfe.arquivo_pdf = item.nfe_pdf;
                item.nfe.arquivo_xml = item.nfe_xml;
            }
        }

        this.setState({
            total: total,
        })

        return entities;
    }

    async downloadFile(file: any, extensao: string): Promise<void> {
        try {
            this.setState({loading: true});

            let result = this.NFeService.downloadLink(file.chaveacesso, extensao);

            this.setState({loading: false});
            let asXml: boolean = extensao == "xml";

            this.download(asXml ? file.arquivo_xml : file.arquivo_pdf, result);
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
        }
    }

    download(filename, link) {
        let element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render() {
        return (
            <div>
                <LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
                    {this.state.datasource.length > 0 ? (
                        <>
                            <Row>
                                <Col md={6} sm={4} lg={6}>
                                    <Card title="Total">
                                        <h1>{this.CommonUtil.FormatBrl(this.state.total)}</h1>
                                    </Card>
                                </Col>
                            </Row>

                            <br/>

                            <Table
                                bordered={true}
                                dataSource={this.state.datasource}
                                columns={this.columns}
                                pagination={false}
                            />
                        </>
                    ) : (
                        <Empty description="Nenhum registro encontrado"/>
                    )}
                </LoadingOverlay>
            </div>
        );
    }
}

export default UmkClienteCompras;

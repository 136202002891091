import React from "react";
import LoadingOverlay from "react-loading-overlay";
import NotificationUtil from "../../../utils/NotificationUtil";
import LicencaService from '../../../services/LicencaService';
import ModalUltil from "../../../utils/ModalUtil";
import { Redirect } from "react-router-dom";
import CookieUtil from "../../../utils/CookieUtil";

class UmkLicencaRenovar extends React.Component<any, any> {
    private NotificationUtil: NotificationUtil;
    private ModalUltil: ModalUltil;
    private CookieUtil: CookieUtil;

    private LicencaService: LicencaService;

	state = {
		loading: false,
        redirect: false,
        redirectPage: '',
        licencaid: undefined
	};

	constructor(props: any) {
		super(props);

        this.NotificationUtil = new NotificationUtil();
        this.ModalUltil = new ModalUltil(props);
        this.CookieUtil = new CookieUtil();

        this.LicencaService = new LicencaService();

		this.load = this.load.bind(this);
        this.getParameters = this.getParameters.bind(this);
        this.validateRenovacao = this.validateRenovacao.bind(this);
	}

	componentDidMount() {
		this.load();
	}

    /**
     * Carrega os dados da URL
     */
	async load(): Promise<void> {
		this.setState({ loading: true });

        this.getParameters();

		this.setState({ loading: false });
	}

    /**
     * Captura o id da licença
     */
    getParameters(): void {
        try {
            let url_string = window.location.href;
            let url = new URL(url_string);
    
            let idlicenca = url.searchParams.get("id");
    
            if (!idlicenca || idlicenca == '0')
                throw new Error("Licença inválida");

            this.setState({
                licencaid: idlicenca
            }, () => this.validateRenovacao());
        } catch (error)  {
            this.ModalUltil.Error(error.message);            
            this.setState({
                redirect: true,
                redirectPage: `${process.env.PUBLIC_URL}/cliente/dashboard`,
            });
        }        
    }

    async validateRenovacao(): Promise<void> {
        try {
            let orderId = this.CookieUtil.Get("OrderID");

            if (orderId) {

            }

        } catch (error) {
            this.ModalUltil.Exception(error);
        }
    }

	render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectPage} />;
        }

		return (
			<div>
				<LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
					
				</LoadingOverlay>
			</div>
		);
	}
}
export default UmkLicencaRenovar;

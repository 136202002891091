import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import UmkAlterarSenha from '../../components/unimake-alterarsenha/UmkAlterarSenha';

const { Title } = Typography;

class AlterarSenhaClienteAuthPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        document.title = "Unimake Software - Cliente - Alterar Senha";
    }

    render() {
        return (
            <div>
                <section className="unimake-container">
                    <div className="content-area">
                        <Row>
                            <Col sm={24} md={24} xs={24}>
                                <div className="content-area">
                                    <Title level={4}>Acesso do Cliente - Nova Senha</Title>
                                    <Title level={5}>Informe sua nova senha</Title>
                                    <Divider />
                                    <UmkAlterarSenha tipo="cliente-auth" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
}
export default AlterarSenhaClienteAuthPage;

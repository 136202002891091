import React from "react";
import ClienteService from "../../services/ClienteService";
import StorageService from "../../services/StorageService";
import IResponse from "../../contract/IResponse";
import {format} from "date-fns";
import ReactDataGrid from "react-data-grid";
import LoadingOverlay from "react-loading-overlay";
import {Button, Col, Empty, Row, Table} from "antd";
import CommonUtil from "./../../utils/CommonUtil";
import {render} from "@testing-library/react";
import ModalUltil from "../../utils/ModalUtil";
import NFeService from "../../services/NFeService";
import IApiResponse from "../../contract/IApiResponse";
import {ILicencaValidate} from "../../contract/licenca/ILicencaValidate";

class UmkClienteNFe extends React.Component<any, any> {
    private CommonUtil: CommonUtil;

    DateColumnFormatter = (value) => {
        return <span> {format(new Date(value), "dd/MM/yyyy")} </span>;
    };

    ValorFormatter = (value) => {
        return <span> R$ {this.CommonUtil.FormatCurrency(value)} </span>;
    };

    OptionsFormatter = (value) => {
        return (
            <Row>
                <Col sm={11} md={11} xs={11}>
                    <Button block type="primary" onClick={() => {
                        this.downloadFile(value, "pdf")
                    }}>
                        PDF
                    </Button>
                </Col>

                <Col sm={1} md={1} xs={1}/>

                <Col sm={11} md={11} xs={11}>
                    <Button block type="primary" onClick={() => {
                        this.downloadFile(value, "xml")
                    }}>
                        XML
                    </Button>
                </Col>
            </Row>
        );
    };

    state = {
        datasource: [],
        loading: false,
    };

    private columns = [
        {
            title: "Tipo",
            key: "tipo",
            dataIndex: "tipo",
            width: 60,
        },
        {
            title: "Emissão",
            key: "demi",
            dataIndex: "demi",
            render: this.DateColumnFormatter,
            width: 100,
        },
        {
            title: "Número",
            key: "numero",
            dataIndex: "numero",
            width: 90,
        },
        {
            title: "Valor",
            key: "total",
            dataIndex: "total",
            render: this.ValorFormatter,
            width: 150,
        },
        {
            title: "Chave de Acesso",
            dataIndex: "chaveacesso",
            key: "chaveacesso",
        },
        {
            title: "Opções",
            key: "opcoes",
            dataIndex: "opcoes",
            render: this.OptionsFormatter,
            width: 170,
        },
    ];

    private ModalUltil: ModalUltil;
    private ClienteService: ClienteService;
    private NFeService: NFeService;
    private storage: StorageService;

    constructor(props: any) {
        super(props);

        this.CommonUtil = new CommonUtil();
        this.ModalUltil = new ModalUltil(props);
        this.ClienteService = new ClienteService();
        this.NFeService = new NFeService();
        this.storage = new StorageService();


        this.Load = this.Load.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        this.Load();
    }

    private async Load() {
        this.setState({loading: true});

        let usuario: any = JSON.parse(this.storage.Get("Usuario"));

        let result = await this.ClienteService.GetNFe(usuario.idcliente);

        this.setState({loading: false});

        let response: IResponse = result.data;

        let valid = this.ClienteService.ValidateLoginFromResponse(response);

        if (valid) {
            let result = this.resolveEntities(response.data);

            this.setState({
                datasource: result,
            });
        }

        this.setState({loading: false});
    }

    private resolveEntities(entities: Array<any>) {
        for (let item of entities) {
            if (item.emissao) item.emissao = format(new Date(item.emissao), "dd/MM/yyyy");

            item.valor = "R$ " + item.valor;

            let opcoes = {
                chaveacesso: item.chaveacesso,
                arquivo_pdf: item.arquivo_pdf,
                arquivo_xml: item.arquivo_xml,
            };

            item.opcoes = opcoes;
        }

        return entities;
    }

    async downloadFile(file: any, extensao: string) {
        try {
            this.setState({loading: true});

            let result = this.NFeService.downloadLink(file.chaveacesso, extensao);

            this.setState({loading: false});
            let asXml: boolean = extensao == "xml";

            this.download(asXml ? file.arquivo_xml : file.arquivo_pdf, result);
        } catch (error) {
            this.setState({loading: false});
            this.ModalUltil.Error(error.message);
        }
    }

    download(filename, link) {
        let element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }


    render() {
        return (
            <div>
                <LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
                    {this.state.datasource.length > 0 ? (
                        <Table
                            bordered={true}
                            dataSource={this.state.datasource}
                            columns={this.columns}
                            pagination={false}
                        />
                    ) : (
                        <Empty description="Nenhum registro encontrado"/>
                    )}
                </LoadingOverlay>
            </div>
        );
    }
}

export default UmkClienteNFe;

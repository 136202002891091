import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Layout } from "antd";
import "./App.less";
import "./App.scss";
import "./assets/scss/unimake-3/Unimake_3.scss";

import MenuNavigation from "./services/MenuNavigation";
import UmkMainNav from "./components/menu/UmkMainNav";
import ScrollTop from "./ScrollTop";
import UmkGoTop from "./components/unimake-gotop/UmkGoTop";
import UmkFooterV3 from "./components/v3/unimake-footer/UmkFooter";

import HomePage from "./pages/Home/HomePage";
import SobrePage from "./pages/Sobre/SobrePage";
import LoginClientePage from "./pages/ClienteArea/LoginClientePage";
import CadastroClientePage from "./pages/ClienteArea/CadastroClientePage";
import ProdutosPage from "./pages/Produtos/ProdutosPage";
import DownloadsPage from "./pages/Downloads/DownloadsPage";
import ContatoPage from "./pages/Contato/ContatoPage";
import CarrinhoPage from "./pages/Loja/CarrinhoPage";
import FecharCompraPage from "./pages/Loja/FecharCompraPage";
import DashboardPage from "./pages/ClienteArea/DashboardClientePage";
import ProfileClientePage from "./pages/ClienteArea/ProfileClientePage";
import BIPage from "./pages/Produtos/BIPage";
import ForcaVendasPage from "./pages/Produtos/ForcaVendasPage";
import UniDANFEPage from "./pages/Produtos/UniDANFEPage";
import UnicoPage from "./pages/Produtos/UnicoPage";
import UnicoAutoPage from "./pages/Produtos/UnicoAutoPage";
import UnicoFeculariaPage from "./pages/Produtos/UnicoFeculariaPage";
import UnicoFerragensPage from "./pages/Produtos/UnicoFerragensPage";
import UnicoMaterialConstrucaoPage from "./pages/Produtos/UnicoMaterialConstrucaoPage";
import UnicoMaterialEletricoPage from "./pages/Produtos/UnicoMaterialEletricoPage";
import NotFoundPage from "./pages/NotFoundPage";
import { Redirect } from "react-router-dom";
import TrabalheConoscoPage from "./pages/TrabalheConosco/TrabalheConoscoPage";
import CurriculoPage from "./pages/TrabalheConosco/CurriculoPage";
import EsqueciMinhaSenhaPage from "./pages/TrabalheConosco/EsqueciMinhaSenhaPage";
import EsqueciMinhaSenhaPageCliente from "./pages/ClienteArea/EsqueciMinhaSenhaClientePage";
import AlterarSenhaPage from "./pages/TrabalheConosco/AlterarSenhaPage";
import InstitucionalPage from "./pages/Sobre/InstitucionalPage";
import Sider from "antd/lib/layout/Sider";
import { Footer } from "antd/lib/layout/layout";
import LicencaPage from './pages/ClienteArea/LicencaPage';
import AlterarSenhaClientePage from "./pages/ClienteArea/AlterarSenhaClientePage";
import AlterarSenhaClienteAuthPage from "./pages/ClienteArea/AlterarSenhaClienteAuthPage";

const { Header, Content } = Layout;

class App extends Component {
	private Menu: MenuNavigation;

	constructor(props: any) {
		super(props);

		this.Menu = new MenuNavigation(props);
	}

	render() {
		return (
			<Layout>
				<Router>
					<ScrollTop>
						<Header className="main-header">
							<UmkMainNav />
						</Header>

						<Content className="main-content-v3">
							{/* <Route path='/404' component={NotFoundPage} />
              <Redirect from='*' to='/404' /> */}

							<Route exact path={`${process.env.PUBLIC_URL}/`} component={this.Menu.OpenPage(HomePage)} />
							{/* <Route
								exact
								path={`${process.env.PUBLIC_URL}/sobre/:tabParam`}
								component={this.Menu.OpenPage(SobrePage)}
							/> */}
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/institucional`}
								component={this.Menu.OpenPage(InstitucionalPage)}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/downloads`}
								component={this.Menu.OpenPage(DownloadsPage)}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/contato`}
								component={this.Menu.OpenPage(ContatoPage)}
							/>

							{/* Produtos */}
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos`}
								component={this.Menu.OpenPage(ProdutosPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/bi`}
								component={this.Menu.OpenPage(BIPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unidanfe`}
								component={this.Menu.OpenPage(UniDANFEPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/forcavendas`}
								component={this.Menu.OpenPage(ForcaVendasPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico`}
								component={this.Menu.OpenPage(UnicoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico-agro`}
								component={this.Menu.OpenPage(UnicoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico-auto`}
								component={this.Menu.OpenPage(UnicoAutoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico-fecularia`}
								component={this.Menu.OpenPage(UnicoFeculariaPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico-ferragens`}
								component={this.Menu.OpenPage(UnicoFerragensPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico-material-construcao`}
								component={this.Menu.OpenPage(UnicoMaterialConstrucaoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/produtos/unico-material-eletrico`}
								component={this.Menu.OpenPage(UnicoMaterialEletricoPage)}
							/>

							{/* Cliente  */}
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/cadastro`}
								component={this.Menu.OpenPage(CadastroClientePage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/login`}
								component={this.Menu.OpenPage(LoginClientePage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/esqueci-minha-senha`}
								component={this.Menu.OpenPage(EsqueciMinhaSenhaPageCliente)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/alterar-senha`}
								component={this.Menu.OpenPage(AlterarSenhaClientePage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/alterar-senha-auth`}
								component={this.Menu.OpenPage(AlterarSenhaClienteAuthPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/dashboard`}
								component={this.Menu.OpenPage(DashboardPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/perfil`}
								component={this.Menu.OpenPage(ProfileClientePage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/licenca/editar`}
								component={this.Menu.OpenPage(LicencaPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/licenca/renovar`}
								component={this.Menu.OpenPage(LicencaPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/cliente/licenca/desativar`}
								component={this.Menu.OpenPage(LicencaPage)}
							/>

							{/* Trabalhe Conosco */}
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/trabalhe-conosco`}
								component={this.Menu.OpenPage(TrabalheConoscoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/trabalhe-conosco/curriculo`}
								component={this.Menu.OpenPage(CurriculoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/trabalhe-conosco/alterar-senha`}
								component={this.Menu.OpenPage(AlterarSenhaPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/trabalhe-conosco/esqueci-minha-senha`}
								component={this.Menu.OpenPage(EsqueciMinhaSenhaPage)}
							/>

							{/* Loja */}
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/loja/carrinho`}
								component={this.Menu.OpenPage(CarrinhoPage)}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/loja/fechar`}
								component={this.Menu.OpenPage(FecharCompraPage)}
							/>
						</Content>

						<UmkGoTop />
						<UmkFooterV3 />
					</ScrollTop>
				</Router>
			</Layout>
		);
	}
}

export default App;

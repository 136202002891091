import React from 'react';
import { Tabs, Card } from 'antd';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const style = {
    width: '95%',
    height: '300px'
}
const { TabPane } = Tabs;

class UmkAboutMap extends React.Component<any, any> {
    state = {
        loading: true
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500);
    }

    render() {
        return (
            <Card loading={this.state.loading}>
                <Tabs defaultActiveKey="1" >

                    <TabPane tab="Campo Mourão" key="1">
                        <div style={{ height: '300px' }}>
                            <Map
                                google={this.props.google}
                                style={style}
                                initialCenter={{
                                    lat: -24.035442,
                                    lng: -52.375598
                                }}
                                zoom={19}
                            >
                                <Marker title={'Unimake Software - Campo Mourão'}></Marker>
                            </Map>
                        </div>
                    </TabPane>

                    <TabPane tab="Paranavaí" key="2">
                        <div style={{ height: '300px', width: '95%' }}>
                            <Map                        
                                google={this.props.google}
                                style={style}
                                initialCenter={{
                                    lat: -23.086056,
                                    lng: -52.448167
                                }}
                                zoom={19}
                            >
                                <Marker title={'Unimake Software - Paranavaí'} ></Marker>
                            </Map>
                        </div>
                    </TabPane>

                </Tabs>
            </Card>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyAX-aiCtgayp3jb6zyqo7PGeRrYyiemQoo')
})(UmkAboutMap)
import React from 'react';
import StorageService from '../../services/StorageService';
import ClienteService from '../../services/ClienteService';
import { format } from 'date-fns';
import ReactDataGrid from 'react-data-grid';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Col, Empty, Row, Table } from 'antd';
import IResponse from '../../contract/IResponse';
import { render } from '@testing-library/react';
import CommonUtil from '../../utils/CommonUtil';

class UmkClienteBoletos extends React.Component<any, any> {
    OptionsFormatter = (value) => {
        let size = ((value.linkboleto != null && value.linkboleto.length > 0) 
        && (value.linkpagseguro != null && value.linkpagseguro.length > 0)) ? 11 : 24;

        return (<Row>
            {value.linkboleto != null && value.linkboleto.length > 0 ? (<><Col sm={size} md={size} xs={size}>
                <Button block type="primary" target="_blank" href={value.linkboleto} >Boleto</Button>
            </Col> <Col sm={1} md={1} xs={1} /></>) : (<></>)}

            {value.linkpagseguro != null && value.linkpagseguro.length > 0 ? (<Col sm={size} md={size} xs={size}>
                <Button block type="primary" target="_blank" href={value.linkpagseguro} >Cartão</Button>
            </Col>) : (<></>)}
        </Row>);
    };

    state = {
        datasource: [],
        loading: false
    }

    private columns = [
        {
            title: 'Vencimento',
            dataIndex: 'vencimento',
            key: 'vencimento',
            width: 100
        },
        {
            title: 'Atraso',
            dataIndex: 'atraso',
            key: 'atraso',
            width: 80
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            width: 150
        },
        {
            title: 'Obsrvações',
            dataIndex: 'observacoes',
            key: 'observacoes',
        },
        {
            title: 'Opções',
            dataIndex: 'opcoes',
            key: 'opcoes',
            render: this.OptionsFormatter,
            width: 170
        },
    ]

    private ClienteService: ClienteService;
    private Common: CommonUtil;
    private storage: StorageService;

    constructor(props: any) {
        super(props);

        this.ClienteService = new ClienteService();
        this.Common = new CommonUtil();
        this.storage = new StorageService();

        this.Load = this.Load.bind(this);
    }

    componentDidMount() {
        this.Load();
    }

    private async Load() {
        this.setState({ loading: true });

        let usuario: any = JSON.parse(this.storage.Get('Usuario'));

        let result = await this.ClienteService.GetBoletos(usuario.idcliente);

        this.setState({ loading: false });

        let response: IResponse = result.data;
        let valid = this.ClienteService.ValidateLoginFromResponse(response);

        if (valid) {
            let result = this.resolveEntities(response.data);

            this.setState({
                datasource: result
            });
        }

        this.setState({ loading: false });
    }

    resolveEntities(entities) {
        for (let item of entities) {

            if (item.vencimento)
                item.vencimento = format((new Date(item.vencimento)), 'dd/MM/yyyy');

            item.valor = this.Common.FormatBrl(item.valor);

            item.opcoes = {};


            if (item.link_boleto.length > 0) {
                item.opcoes.linkboleto = item.link_boleto;
                // item.opcoes += `<a href="${item.link_boleto}" /> </a> </br>`;
            }

            if (item.link_pagseguro.length > 0) {
                item.opcoes.linkpagseguro = item.link_pagseguro;
                // item.opcoes += `<a href="${item.link_pagseguro}" /> </a>`;
            }
        }

        return entities;
    }

    render() {
        return (
            <div>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Carregando dados...'
                >
                    {
                        (this.state.datasource.length > 0) ? (
                            <Table bordered={true} dataSource={this.state.datasource} columns={this.columns} pagination={false} />
                        ) : (
                            <Empty description="Nenhum registro encontrado" />
                        )
                    }

                </LoadingOverlay>
            </div>
        );
    }
}
export default UmkClienteBoletos;
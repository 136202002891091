import React from "react";
import { Button, Row, Col, Typography, Divider } from "antd";
import UmkClienteLogin from "../../components/forms/unimake-cliente-login/UmkClienteLogin";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;
class LoginClientePage extends React.Component<any, any> {
	componentDidMount() {
		document.title = "Unimake Software - Login";
	}

	render() {
		return (
			<div>
				<section className="unimake-container unimake-content">
					<Row justify="space-between">
						<Col sm={11} md={11} xs={24}>
							<Title level={4}>Ainda não tenho cadastro</Title>
							<Divider />
							<Paragraph>
								Nesta área você pode: <br />
								<br />
								<ul>
									<li>Comprar, consultar e renovar licenças.</li>
									<li>Baixar as NF e consultar detalhes de suas compras.</li>
									<li>Alterar dados cadastrais, entre outras.</li>
								</ul>
								<br />
							</Paragraph>
							<Link to={`${process.env.PUBLIC_URL}/cliente/cadastro`}>
								<Button block shape="round" type="primary">
									Cadastrar
								</Button>
							</Link>
						</Col>
						<Col sm={11} md={11} xs={24}>
							<Title level={4}>Acesso a área do cliente</Title>
							<Divider />
							<UmkClienteLogin />
						</Col>
					</Row>
				</section>
			</div>
		);
	}
}
export default LoginClientePage;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faLinkedin, faFacebook, faWordpressSimple } from "@fortawesome/free-brands-svg-icons";
import React from 'react';
import { Col, Row } from 'antd';
import { flipInX } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

const styles = {
    flip: {
        animation: 'x 1s',
        animationName: Radium.keyframes(flipInX, 'bounce')
    }
}

class UmkSocialBar extends React.Component<any, any> {
    render() {
        return (
            <div className="social-bar">
                <Row>
                    <Col xs={4} sm={4} md={4} />

                    <Col xs={4} sm={4} md={4}>
                        <a style={styles.flip} href="https://twitter.com/Unimake"><FontAwesomeIcon color="white" style={{fontSize: 24}} icon={faTwitter} /></a>
                    </Col>
                    <Col xs={4} sm={4} md={4}>
                        <a style={styles.flip} href="https://www.youtube.com/channel/UCOSPmRhLgIFJU-pAy8Sogdw"><FontAwesomeIcon color="white" style={{fontSize: 24}} icon={faYoutube} /></a>
                    </Col>

                    <Col xs={4} sm={4} md={4}>
                        <a style={styles.flip} href="https://www.linkedin.com/company/unimake-software/"><FontAwesomeIcon color="white" style={{fontSize: 24}} icon={faLinkedin} /></a>
                    </Col>

                    <Col xs={4} sm={4} md={4}>
                        <a style={styles.flip} href="https://www.facebook.com/unimake/"><FontAwesomeIcon color="white" style={{fontSize: 24}} icon={faFacebook} /></a>
                    </Col>

                    <Col xs={4} sm={4} md={4}>
                        <a style={styles.flip} href="https://blog.unimake.com.br/"><FontAwesomeIcon color="white" style={{fontSize: 24}} icon={faWordpressSimple} /></a>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default UmkSocialBar;
import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import UmkSliderContainer from "../../components/unimake-slider-container/UmkSliderContainer";
import ContatoComponent from "./components/ContatoComponent";

class UnicoMaterialConstrucaoPage extends React.Component<any, any> {
	state = {
		logoUnicoMaterialContrucao: require("../../assets/img/products/topo/unico-materiais-construcao.png"),
	};
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		document.title = "Unimake Software - Materiais de construção";
	}

	render() {
		return (
			<>
				<div className="topo-border-rounded color-materiais-construcao">
					<Row align="middle" justify="space-around">
						<Col
							lg={{ order: 1, span: 10, offset: 2 }}
							xs={{ order: 2, span: 20 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">Único ERP</h1>
							<h2 className="hidden-xs hidden-sm hidden-md">Materiais de Construção</h2>

							<p>A organização que fará sua empresa crescer sem limites!</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoUnicoMaterialContrucao}></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<p>
									Um sistema de gestão específico no segmento de materiais de construção como o Único
									ERP ajuda na organização e gerenciamento eficaz da sua empresa.
								</p>

								<p>Destaque-se da concorrência, fidelize seus clientes e venda mais!</p>
							</div>

							<div className="featuresBox">
								<Row>
									<Col sm={24}>
										<h3 style={{ fontSize: 32, fontWeight: 400 }}>Conheça alguns módulos:</h3>
									</Col>
								</Row>

								<Row justify="space-between">
									<Col sm={11} md={7}>
										<b>Controle de venda/entrega futura</b>
										<p>
											Permite fechar uma venda com vários itens e liberá-los em várias entregas
											controlando o saldo disponível
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Simulação do preço de venda</b>
										<p>
											O vendedor pode simular em tempo real a lucratividade dos itens do pedido e
											verificar qual faixa de preço pode praticar
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Venda rápida</b>
										<p>
											O vendedor lança os itens de acordo com as requisições do cliente e informa
											as condições de pagamento no fechamento
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Controle de comissões</b>
										<p>Permite parametrizar por vendedor, por produto ou por faixa de descontos</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Manuseio de produtos por código de barras</b>
										<p>
											Integra o software ao leitor de código de barras (fixo ou pistola) que lê os
											códigos de barras do produto no ato de recepção e saída de produtos
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Endereçamento de produtos no estoque</b>
										<p>
											Ao fazer a consulta de um produto no Único o usuário é informado do corredor
											e a prateleira em que o produto se encontra dentro do depósito
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Controle de estoque mínimo/máximo</b>
										<p>
											O Único alerta o gestor de compras quando um produto atinge o estoque
											mínimo, bem como avisa em caso de excesso de estoque de um determinado item
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Análise de crédito e bloqueio automático</b>
										<p>
											O software faz análise constante do histórico de credito dos clientes
											liberando ou bloqueando crédito conforme parâmetros determinados pelo
											financeiro
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Controle de cheques pré-datados</b>
										<p>
											Controla origem e destino dos cheques recebidos permitindo vincular cheques
											de terceiros ao cliente pagador e prazos de depósito
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Controle de acesso de usuários</b>
										<p>
											Permite parametrizar as permissões que cada usuário terá dentro do software
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Controle financeiro total</b>
										<p>
											Controles de caixa e contas bancárias Recebimentos com cartão Controle de
											pagamentos Emissão e baixa automática de boletos Bancários
										</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Contabilidade e escrita fiscal e SPED</b>
										<p>Para que sua empresa cumpra todas as Exigências fiscais vigentes</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Documentos Fiscais Eletrônicos</b>
										<p>Emita NF-e, NFC-e, S@T, CT-e, CC-e, MDF-e em regularidade com o fisco.</p>
									</Col>

									<Col sm={11} md={7}>
										<b>Integração com BI</b>
										<p>
											Agregando os módulos de BI e FV da Unimake você turbina a gestão do seu
											negócio{" "}
										</p>
										<Link to="/produtos/bi">Saiba mais</Link>
									</Col>

									<Col sm={11} md={7}>
										<b>Relatórios gerenciais</b>
										<p>
											Uma ampla gama de relatórios que permitem sua análise das operações tais
											como estoques, contas a pagar e receber, lucratividade entre outros.
										</p>
									</Col>
								</Row>
							</div>

							<br />

							{/* <div className="color-black-transparent">
								<LazyLoad>
									<img
										style={{
											maxWidth: "70%",
											marginRight: "auto",
											marginLeft: "auto",
											display: "block",
										}}
										src={require("../../assets/img/products/unico_materiais_const_novo.png")}
										alt=""
									/>
								</LazyLoad>
							</div> */}
						</div>
					</div>
				</section>

				<div className="autoPecasBanner color-black-transparent">
					<div className="autoPecasBanner-container">
						<Row className="hidden-xs hidden-sm">
							<Col sm={24}>
								<LazyLoad>
									<img
										style={{
											maxWidth: "70%",
											marginRight: "auto",
											marginLeft: "auto",
											display: "block",
										}}
										src={require("../../assets/img/products/unico_materiais_const_novo.png")}
										alt=""
									/>
								</LazyLoad>

								<br />
							</Col>
						</Row>

						<Row align="middle" className="construcao-middle-row">
							<Col md={10} className="hidden-xs hidden-sm hidden-md">
								<LazyLoad>
									<img
										className="imageLeft-fec"
										src={require("../../assets/img/products/unico_mat_const_novo2.png")}
									/>
								</LazyLoad>
							</Col>

							<Col xs={24} lg={14}>
								<p style={{ fontSize: "25px" }}>
									O segmento de materiais de construção é muito dinâmico e sua empresa tem que estar
									sempre à frente desse mercado.
									<br />
									<br />
									Faça isso com o Único ERP Materiais de construção
								</p>
							</Col>
						</Row>
					</div>
				</div>

				{/* <div>
					<Row>
						<Col sm={24} md={24}>
							<h1 style={{ fontSize: 32, fontWeight: 300, textAlign: "center" }}>
								Alguns casos de sucesso
							</h1>

							<div style={{ height: 200 }}>
								<UmkSliderContainer />
							</div>
						</Col>
					</Row>
				</div> */}

				<ContatoComponent />
			</>
		);
	}
}
export default UnicoMaterialConstrucaoPage;

import React from "react";
import {Col, Row} from "antd";
import UmkSlide from "../../components/unimake-slide/UmkSlide";
import "./SobrePage.scss";

class InstitucionalPage extends React.Component<any, any> {
    imagemTitulos = {
        // historia: require("../../assets/img/sobre/sobre-historia.jpeg"),
        missao: require("../../assets/img/sobre/sobre-missao.png"),
        parceiros: require("../../assets/img/sobre/sobre-parceiros.jpeg"),
        relacoes: require("../../assets/img/sobre/sobre-relacoes.jpeg"),
        tecnologias: require("../../assets/img/sobre/sobre-tecnologias.jpeg"),
    };

    state = {
        SlidesRelacoes: [
            {
                Image: require("../../assets/img/min/about/aciap.png"),
                Caption: "ACIAP",
            },
            {
                Image: require("../../assets/img/min/about/acicam.png"),
                Caption: "ACICAM",
            },
            {
                Image: require("../../assets/img/min/about/apl.png"),
                Caption: "APL",
            },
            {
                Image: require("../../assets/img/min/about/assespro.png"),
                Caption: "ASSESPRO",
            },
            {
                Image: require("../../assets/img/min/about/bymaringa.png"),
                Caption: "Software By Maringá",
            },
            {
                Image: require("../../assets/img/min/about/nexti.png"),
                Caption: "NexTI",
            },
            {
                Image: require("../../assets/img/min/about/utfpr.png"),
                Caption: "UTFPR",
            },
            {
                Image: require("../../assets/img/min/about/sindti.png"),
                Caption: "SindTi",
            },
        ],
        SlidesTecnologias: [
            {
                Image: require("../../assets/img/min/about/xharbour.jpg"),
                Caption: "XHarbour",
            },
            {
                Image: require('../../assets/img/min/about/tecnologia_clarion.jpeg'),
                Caption: 'Clarion'
            },
            {
                Image: require("../../assets/img/min/about/dotnet.jpg"),
                Caption: ".NET",
            },
            {
                Image: require("../../assets/img/min/about/xamarin.jpg"),
                Caption: ".NET",
            },
            {
                Image: require("../../assets/img/min/about/postgre.jpg"),
                Caption: "PostgreSQL",
            },
            {
                Image: require("../../assets/img/min/about/windows.png"),
                Caption: "Windows",
            },
            {
                Image: require("../../assets/img/min/about/extnet.png"),
                Caption: "ExtNet",
            },
        ],
        SlidesParceiros: [
            {
                Image: require("../../assets/img/min/about/microsoft.png"),
                Caption: "Microsoft",
            },
            {
                Image: require("../../assets/img/min/about/bndes.png"),
                Caption: "BNDES",
            },
            {
                Image: require("../../assets/img/min/about/sescon.png"),
                Caption: "Sescon",
            },
        ],
    };

    constructor(props: any) {
        super(props);


        this.getSection = this.getSection.bind(this);
        this.sectionSroll = this.sectionSroll.bind(this);
    }

    componentDidMount() {
        document.title = "Unimake Software - Sobre";
        setTimeout(() => {
            this.getSection();
        }, 200);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.getSection();
    }

    /**
     * Extrai a seção da URL e faz o scroll
     */
    getSection(): void {
        let url_string = window.location.href;
        let url = new URL(url_string);

        let section = url.searchParams.get("s");
        if (section) {
            this.sectionSroll(section);
        }
    }

    /**
     * Realiza o scroll para a seção
     * @param section 'historia' | 'missao' | 'parceiros' | 'relacoes' | 'tecnologias'
     */
    sectionSroll(section: string): void {
        let yOffset = -35;
        let element = document.getElementById(section);

        switch (section) {
            case 'historia':
                yOffset = -45;
                break;
            case 'missao':
                yOffset = -50;
                break;
        }
        let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    render() {
        return (
            <section className="unimake-area-v3">
                <div className="unimake-container">
                    <Row>
                        <Col sm={24} md={24}>
                            <h1 className="ant-typography">Sobre</h1>
                        </Col>
                    </Row>
                    <Row id="historia">
                        <Col sm={24} md={24}>
                            <h2>História</h2>
                            {/* <img src={this.imagemTitulos.historia} /> */}
                            <div>
                                <p>
                                    A Unimake Software foi criada em outubro de 2000, resultante da fusão das empresas
                                    Tendência Informática e LW Softwares, duas software houses atuantes na região
                                    noroeste do Paraná ambas fundadas em meados da década de 90.
                                </p>

                                <p>
                                    Inicialmente fornecendo sistemas integrados de Gestão (ERP), a empresa conquistou
                                    centenas de clientes nos segmentos de varejo e agroindústria em diversas regiões do
                                    país.
                                </p>

                                <p>
                                    O empenho e a busca constante por melhoria de seus fundadores fez com que ao longo
                                    de sua história a Unimake atraísse cada vez mais pessoas compromissadas com a
                                    qualidade e a seriedade em fornecer software para gestão, sem dúvida a ferramenta
                                    mais importante de qualquer empresa.
                                </p>

                                <p>
                                    Em 2005 seu principal software de gestão, o Único, foi compatibilizado com o sistema
                                    Windows® e em seguida, em 2006, o antigo banco de dados DBF foi substituído pelo
                                    moderno PostgreSQL, um dos mais modernos e difundidos bancos de dados do mundo.
                                </p>

                                <p>
                                    Em 2008, a chegada da Nota Fiscal Eletrônica (NF-e) abre novos mercados e
                                    oportunidades de inovação para a empresa, que lança diversos aplicativos ligados ao
                                    tema, dentre eles destaque para o Uninfe, primeiro aplicativo open source
                                    (código-livre e gratuito) para transmissão dos arquivos XML da NF-e, que rapidamente
                                    atraiu o interesse do mercado, sendo adotado por milhares de empresas de software no
                                    país.
                                </p>

                                <p>
                                    A história da Unimake não acaba aqui, muito pelo contrário, apenas começa, e seu
                                    sucesso só é possível graças aos colaboradores, aos clientes e aos parceiros que
                                    ajudam a escrevê-la a cada novo dia.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row id="missao">
                        <Col sm={24} md={24}>
                            <h2 className="hidden-md hidden-lg">Missão, Visão e Valores</h2>
                            <img className="img-titulo hidden-xs hidden-sm" src={this.imagemTitulos.missao}/>

                            <p className="missao-center">
                                <b>Missão:</b> <br/>
                                Desenvolver software que melhore a vida das pessoas.
                                <br/>
                                <br/>
                                <b>Visão:</b>
                                <br/>
                                Tornar-se uma grande empresa do setor, desenvolvendo pessoas e fornecendo tecnologia
                                como instrumento de geração de valor.
                                <br/>
                                <br/>
                                <b>Valores:</b>
                                <br/>
                                <ul>
                                    <li>Desenvolvimento e valorização das pessoas.</li>
                                    <li>Seriedade no relacionamento com nossos clientes.</li>
                                    <li>Rentabilidade das operações.</li>
                                    <li>Integridade no manuseio de informações.</li>
                                </ul>
                            </p>
                        </Col>
                    </Row>

                    <Row id="parceiros">
                        <Col sm={24} md={24}>
                            <h2>Parceiros</h2>
                            {/* <img className="img-titulo" src={this.imagemTitulos.parceiros} /> */}

                            <div className="slides-center-about">
                                <UmkSlide Slides={this.state.SlidesParceiros}/>
                            </div>
                        </Col>
                    </Row>

                    <Row id="relacoes">
                        <Col sm={24} md={24}>
                            <h2>Relações Institucionais</h2>
                            {/* <img className="img-titulo" src={this.imagemTitulos.relacoes} /> */}

                            <div className="slides-center-about">
                                <UmkSlide Slides={this.state.SlidesRelacoes}/>
                            </div>
                        </Col>
                    </Row>

                    <Row id="tecnologias">
                        <Col sm={24} md={24}>
                            <h2>Tecnologias</h2>
                            {/* <img className="img-titulo" src={this.imagemTitulos.tecnologias} /> */}

                            <div className="slides-center-about">
                                <UmkSlide Slides={this.state.SlidesTecnologias}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
}

export default InstitucionalPage;

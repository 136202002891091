import IProdutoLoja from "../contract/IProdutoLoja";

var produtos: Array<IProdutoLoja> = [
	{
		ID: undefined,
		color: "blue",
		Buy: false,
		Download: true,
		Header: "Gemini",
		Titulo: "Gemini",
		Categorias: ["Backup"],
		Td1Text: "",
		SaibaMais: "https://www.unimake.com.br/gemini/",
		LinkExterno: true,
		DownloadLink: `${process.env.PUBLIC_URL}/downloads#gemini`,
		Image: require("../assets/img/products/slide/gemini.png"),
		Text: `Serviço de backup na nuvem monitorado.`,
	},
	{
		ID: 3,
		color: "#2273ab",
		Buy: true,
		Header: "UniDANFE",
		Titulo: "UniDANFE",
		Categorias: ["DocumentosFiscais"],
		Td1Text: "R$ #preco# (Anual)",
		Td2Text: "Download Free",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/unidanfe`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/unidanfe.png"),
		Text: `Componente de integração, impressão e envio do XML e do PDF de documentos fiscais.`,
	},
	{
		ID: 2,
		color: "orange",
		Buy: true,
		Header: "DANFE View Plus",
		Titulo: "DANFE View Plus",
		Categorias: ["DocumentosFiscais"],
		Td1Text: "R$ #preco# (Anual)",
		SaibaMais: "https://www.unimake.com.br/danfeview/#funcionalidades",
		LinkExterno: true,
		Image: require("../assets/img/products/slide/danfeviewplus.png"),
		Text: `Solução em gerenciamento de notas fiscais, que faz o downlaod de todas as NF-es e CT-es emitidas pelo seu CNPJ.`,
	},
	{
		ID: 14,
		color: "orange",
		Buy: true,
		Header: "DANFE View OFFICE",
		Titulo: "DANFE View OFFICE",
		Categorias: ["DocumentosFiscais"],
		Td1Text: "R$ #preco# (Anual)",
		LinkExterno: true,
		SaibaMais: "https://www.unimake.com.br/danfeview/#funcionalidades",
		Image: require("../assets/img/products/slide/danfeview.png"),
		Text: `Gerenciador de XML's que faz download da SEFAZ (NF-e e CT-e), armazena, imprime, gera PDF, manifesta, faz backup, emite relatórios de NF-e, NFC-e, CT-e, MDF-e, CF-e/SAT, etc. Licença Office para múltiplos CNPJ.`,
	},
	{
		ID: undefined,
		color: "#238883",
		Buy: false,
		Contato: true,
		Header: "Único ERP | Auto Peças",
		Titulo: "Auto Peças",
		Categorias: ["Varejo"],
		Td1Text: "",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/unico-auto`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/unico_varejo_auto.png"),
		Text: `A melhor solução para sua Auto Peças`,
	},
	{
		ID: undefined,
		color: "#238883",
		Buy: false,
		Contato: true,
		Header: "Único | Materiais Elétricos",
		Titulo: "Materiais Elétricos",
		Categorias: ["Varejo"],
		Td1Text: "",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/unico-material-eletrico`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/unico_varejo_eletrico.png"),
		Text: `Solução de gestão exclusiva para lojas de Materiais Elétricos`,
	},
	{
		ID: undefined,
		color: "#238883",
		Buy: false,
		Contato: true,
		Header: "Único | Materiais de Construção",
		Titulo: "Materiais de Construção",
		Categorias: ["Varejo"],
		Td1Text: "",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/unico-material-construcao`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/unico_varejo_construcao.png"),
		Text: `Solução de gestão exclusiva para materiais de construção`,
	},
	{
		ID: undefined,
		color: "#238883",
		Buy: false,
		Contato: true,
		Header: "Único | Ferragens",
		Titulo: "Ferragens",
		Categorias: ["Varejo"],
		Td1Text: "",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/unico-ferragens`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/unico_varejo_ferragens.png"),
		Text: `Solução de gestão exclusiva para lojas de ferragens`,
	},
	{
		ID: undefined,
		color: "#238883",
		Buy: false,
		Contato: true,
		Header: "Único AGRO",
		Titulo: "AGRO",
		Categorias: ["Agronegocio"],
		Td1Text: "",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/unico-fecularia`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/unico_agro.png"),
		Text: `Sistema de gestão para Agroindústria.`,
	},
	{
		ID: undefined,
		color: "#27be51",
		Buy: false,
		Download: true,
		DownloadLink: "/downloads#uninfe",
		Header: "UniNFe",
		Titulo: "UniNFe",
		Categorias: ["DocumentosFiscais"],
		Td1Text: "Download Free",
		SaibaMais: "https://unimake.com.br/uninfe",
		LinkExterno: true,
		Image: require("../assets/img/products/slide/uninfe.png"),
		Text: `Componente gratuito e open source para envio de arquivos XMl's de NF-e, CT-e, MDF-e, NFS-e, EFD-Reinf e E-Social. `,
	},
	{
		ID: undefined,
		color: "#333333",
		Buy: false,
		Download: false,
		DownloadLink: "",
		Header: "Business Inteligence",
		Titulo: "Business Inteligence",
		Categorias: ["Varejo", "Agronegocio"],
		Td1Text: "Interpretação e análise inteligente de negócios.",
		SaibaMais: `${process.env.PUBLIC_URL}/produtos/bi`,
		LinkExterno: false,
		Image: require("../assets/img/products/slide/bi.png"),
		Text: ``,
	},
	// {
	// 	ID: undefined,
	// 	color: "#772a40aa",
	// 	Buy: false,
	// 	Contato: true,
	// 	Header: "Força de Vendas Mobile",
	// 	Titulo: "Força de Vendas Mobile",
	// 	Categorias: ["Varejo", "Agronegocio"],
	// 	Td1Text: "",
	// 	SaibaMais: `${process.env.PUBLIC_URL}/produtos/forcavendas`,
	// 	LinkExterno: false,
	// 	Image: require("../assets/img/products/slide/forca-de-vendas.png"),
	// 	Text: ``,
	// },
	// {
	//   ID: undefined,
	//   color: 'orange',
	//   Buy: false,
	//   Download: false,
	//   Header: 'DANFE View Cloud',
	//   Categorias: ['DocumentosFiscais'],
	//   Td1Text: '',
	//   SaibaMais: 'https://www.unimake.com.br/danfeview/#funcionalidades',
	//   LinkExterno: true,
	//   Image: require('../assets/img/products/slide/danfeviewcloud_novo.png'),
	//   Text: `Aplicativo WEB de gestão de notas e outros documentos fiscais, que faz o download de todas as NF-es e CT-es emitidos para seu CNPJ.`
	// },
	// {
	// 	ID: undefined,
	// 	color: "unico",
	// 	Buy: false,
	// 	Contato: true,
	// 	Header: "Visualizador de DANFE Online",
	// 	Titulo: "Visualizador de DANFE Online",
	// 	Categorias: ["DocumentosFiscais"],
	//  Td1Text: '',
	// 	SaibaMais: `${process.env.PUBLIC_URL}/produtos/visualizador-danfe`,
	// 	LinkExterno: false,
	// 	Image: require("../assets/img/products/slide/danfeview.png"),
	// 	Text: `Abra arquivos XML ou DNF para visualizar ou imprimir o documento fiscal eletrônico (DANFE, CC-e, DACTE ou DAMDFE).`,
	// },
];

export default produtos;

import { Radio, Row, Col } from "antd";
import React from "react";
import UmkProduto from "../unimake-produto/UmkProduto";
import Slider from "react-slick";

import IProdutoLoja from "./../../../contract/IProdutoLoja";
import UmkLeftButton from "../../unimake-slider/UmkLeftButton";
import UmkRightButton from "../../unimake-slider/UmkRightButton";

const options = [
	{ label: "Todos", value: "Todos" },
	{ label: "Agronegócio", value: "Agronegocio" },
	{ label: "Varejo", value: "Varejo" },
	{ label: "Documentos Fiscais", value: "DocumentosFiscais" },
	{ label: "Backup", value: "Backup" },
];

class UmkProdutoSlider extends React.Component<any, any> {
	slider;

	responsive = [
		{
			breakpoint: 1380,
			slidesToShow: 6,
		},
		{
			breakpoint: 1024,
			slidesToShow: 5,
		},
		{
			breakpoint: 600,
			slidesToShow: 3,
		},
		{
			breakpoint: 480,
			slidesToShow: 2,
		},
	];

	static defaultProps = {
		Produtos: [],
	};

	state = {
		filter: "Todos",
		produtos: [],
		sourceProdutos: [],
		settings: {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 6,
			slidesToScroll: 1,
			arrows: true,
			rows: 1,
			prevArrow: <UmkLeftButton />,
			nextArrow: <UmkRightButton />,
			responsive: [
				{
					breakpoint: 1380,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						infinite: true,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						infinite: true,
					},
				},
			],
		},
	};

	constructor(props: any) {
		super(props);

		this.onChange4 = this.onChange4.bind(this);
		this.updateSettingsQtde = this.updateSettingsQtde.bind(this);
	}

	componentDidMount() {
		this.setState({
			produtos: this.props.Produtos,
			sourceProdutos: this.props.Produtos,
		});

		let quantidadeMostrar: number = this.getQuantidadeShow();
		this.updateSettingsQtde(quantidadeMostrar);
	}

	onChange4 = (e) => {
		let filtro = e.target.value;

		this.setState({
			filter: filtro,
		});

		let quantidadeMostrar: number = this.getQuantidadeShow();

		if (filtro == "Todos") {
			this.updateSettingsQtde(quantidadeMostrar);
			this.setState({
				produtos: this.state.sourceProdutos,
			});
		} else {
			let newProdutos = [];
			let produtos = this.state.sourceProdutos;

			produtos.forEach((el) => {
				let result = this.filterProdutos(el, filtro);

				if (result) newProdutos.push(result);
			});

			quantidadeMostrar = quantidadeMostrar > newProdutos.length ? newProdutos.length : quantidadeMostrar;

			this.updateSettingsQtde(quantidadeMostrar);

			this.setState({
				produtos: newProdutos,
			});
		}
	};

	getQuantidadeShow(): number {
		let width: number = window.innerWidth;

		let quantidadeMostrar: number = 0;
		this.responsive.forEach((value) => {
			if (quantidadeMostrar === 0 && width >= value.breakpoint) {
				quantidadeMostrar = value.slidesToShow;
			}
		});

		if (quantidadeMostrar === 0) {
			quantidadeMostrar = this.responsive[this.responsive.length - 1].slidesToShow;
		}

		return quantidadeMostrar;
	}

	filterProdutos(produto: IProdutoLoja, filter: String) {
		if (produto.Categorias) {
			if (produto.Categorias.includes(filter)) {
				return produto;
			}
		}
	}

	updateSettingsQtde(qtdeShow) {
		this.setState({
			settings: {
				dots: false,
				infinite: true,
				speed: 500,
				slidesToShow: qtdeShow,
				slidesToScroll: 1,
				arrows: true,
				rows: 1,
				prevArrow: <UmkLeftButton />,
				nextArrow: <UmkRightButton />,
				responsive: [
					{
						breakpoint: 1380,
						settings: {
							slidesToShow: qtdeShow,
							slidesToScroll: 1,
							infinite: true,
						},
					},
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: qtdeShow,
							slidesToScroll: 1,
							infinite: true,
						},
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: qtdeShow,
							slidesToScroll: 1,
							infinite: true,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: qtdeShow,
							slidesToScroll: 1,
							infinite: true,
						},
					},
				],
			},
		});
	}

	render() {
		const { filter } = this.state;

		return (
			// Adicionar Waiting
			<div>
				<div>
					<Row>
						<Col md={24} sm={24} xs={24}>
							<Radio.Group
								options={options}
								onChange={this.onChange4}
								value={filter}
								optionType="button"
								buttonStyle="solid"
							/>
						</Col>
					</Row>
				</div>

				<Slider style={{ display: "block" }} ref={(slider) => (this.slider = slider)} {...this.state.settings}>
					{this.state.produtos.map((item: IProdutoLoja) => (
						<div key={item.Text}>
							<UmkProduto Produto={item} />{" "}
						</div>
					))}
				</Slider>
			</div>
		);
	}
}
export default UmkProdutoSlider;

import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class UmkLeftNav extends React.Component<any, any> {
	static defaultProps = {
		Mode: "horizontal",
	};

	render() {
		return (
			<Menu className="menu-unimake" mode={this.props.Mode} defaultSelectedKeys={["home"]}>
				<Link to="/">
					<img
						className="logo-image hidden-xs hidden-sm hidden-md"
						src={process.env.NODE_ENV === 'production' ? require("../../assets/img/unimakelogo.png") : ""}
					/>
				</Link>
				<Menu.Item key="home">
					<Link to="/">Home</Link>
				</Menu.Item>
				<Menu.Item key="institucional">
					<Link to="/institucional">Sobre</Link>
				</Menu.Item>
				<Menu.Item key="produtos">
					<Link to="/produtos">Produtos</Link>
				</Menu.Item>
				<Menu.Item key="downloads">
					<Link to="/downloads">Downloads</Link>
				</Menu.Item>
				<Menu.Item key="trabalhe">
					<Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
				</Menu.Item>
				<SubMenu key="blogs" title={<span>Blogs</span>}>
					<MenuItemGroup key="menu_blogs" title="Blogs">
						<Menu.Item key="blog:1">
							<a target="_blank" rel="noopener noreferrer" href="https://blog.unimake.com.br/">
								Unimake
							</a>
						</Menu.Item>
						<Menu.Item key="blog:2">
							<a target="_blank" rel="noopener noreferrer" href="https://blog.unimake.com.br/gemini">
								Gemini
							</a>
						</Menu.Item>
						<Menu.Item key="blog:3">
							<a target="_blank" rel="noopener noreferrer" href="https://blog.unimake.com.br/uninfe">
								UniNFe
							</a>
						</Menu.Item>
					</MenuItemGroup>
				</SubMenu>
				<Menu.Item key="contato">
					<Link to="/contato">Contato</Link>
				</Menu.Item>
			</Menu>
		);
	}
}
export default UmkLeftNav;

import React from "react";
import UmkClienteCadastro from "../../components/forms/unimake-cliente-cadastro/UmkClienteCadastro";

class CadastroClientePage extends React.Component<any, any> {
	componentDidMount() {
		document.title = "Unimake Software - Cadastro";
	}

	render() {
		return (
			<div>
				<section className="unimake-container">
					<UmkClienteCadastro />
				</section>
			</div>
		);
	}
}
export default CadastroClientePage;

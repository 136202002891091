import React, { Component } from "react";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import UmkSocialBar from "../unimake-socialbar/UmkSocialBar";

class UmkFooter extends Component {
	render() {
		return (
			<div>
				<footer className="footer">
					<div className="footer-container">
						<Row style={{ paddingBottom: "80px" }}>
							<Col md={2} sm={2} />

							<Col xs={24} md={6} sm={6}>
								<img
									style={{
										display: "block",
										marginLeft: "auto",
										marginRight: "auto",
										paddingBottom: 10,
									}}
									className="hidden-xs hidden-sm hidden-md"
									src={require("../../../assets/img/unimakelogo.png")}
								/>
								<div
									style={{
										display: "block",
										marginLeft: "auto",
										marginRight: "auto",
										paddingBottom: 10,
									}}>
									<UmkSocialBar />
								</div>
							</Col>

							<Col xs={1} md={1} sm={1} />

							<Col md={5} sm={5}>
								<p>
									<span className="umk-footer-titulo">Sobre</span>
								</p>
								<ul className="page-footer-list-v3">
									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to="/institucional?s=historia"> História</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to="/institucional?s=missao"> Missão, Visão e Valores</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to="/institucional?s=parceiros"> Parceiros</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to="/institucional?s=relacoes"> Relações Institucionais</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to="/institucional?s=tecnologias"> Tecnologias</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/trabalhe-conosco`}> Trabalhe Conosco</Link>
									</li>
								</ul>
							</Col>

							<Col md={4} sm={4}>
								<p>
									<span className="umk-footer-titulo">Produtos</span>
								</p>

								<ul className="page-footer-list-v3">
									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<a href="https://unimake.com.br/danfeview"> DANFE View</a>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unidanfe`}> UniDANFE</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<a href="https://unimake.com.br/uninfe"> UniNFe</a>
									</li>

									{/* <li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unico`}> Único</Link>
									</li> */}

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos`}> Mais Produtos</Link>
									</li>
								</ul>
							</Col>

							<Col xs={1} md={0} sm={0}></Col>

							<Col md={4} sm={4}>
								<p>
									<span className="umk-footer-titulo">Soluções</span>
								</p>

								<ul className="page-footer-list-v3">
									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unico-fecularia`}> Agro</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unico-material-construcao`}>
											{" "}
											Materiais de Construção
										</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unico-material-eletrico`}>
											{" "}
											Materiais Elétricos
										</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unico-auto`}> Auto-Peças</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<Link to={`${process.env.PUBLIC_URL}/produtos/unico-ferragens`}>
											{" "}
											Ferragens
										</Link>
									</li>

									<li>
										<FontAwesomeIcon color="#49475c" icon={faAngleRight} />
										<a href="https://danfeview.com.br"> Armazenamento de NF-e</a>
									</li>
								</ul>
							</Col>

							<Col md={1} sm={1}></Col>
						</Row>
						<hr />
						<Row>
							<p
								style={{
									color: "#ccc",
									textAlign: "center",
									margin: "auto",
									marginRight: "auto",
									display: "block",
								}}>
								&copy; Copyright
								<script>document.write((new Date()).getFullYear())</script> -
								Unimake Software Todos os direitos reservados.
							</p>
						</Row>
					</div>
				</footer>
			</div>
		);
	}
}
export default UmkFooter;

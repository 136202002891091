import React from 'react';


class UmkBlogPost extends React.Component<any, any> {
    static defaultProps = {
        Image: '',
        Title: '',
        Link: ''
    }

    state = {
        Image: ''
    }

    componentDidMount() {
        if (this.props.Image === 0) {
            this.setState({
                Image: require('../../../assets/img/categorias/02.png')
            });
        } else {
            this.setState({
                Image: this.props.Image
            });
        }
    }


    render() {
        return (
            <div className="image-container">
                <a href={this.props.Link} target="_blank">
                    <img className="blogpostimg" src={this.state.Image}></img>
                    <div className="blog-titulo-image"><span style={{ fontSize: 18, color: "#333", fontWeight: "bold" }}>{this.props.Title}</span></div>
                </a>
            </div>
        );
    }
}
export default UmkBlogPost;
import React from "react";
import { Row, Col, Card, Tabs, Collapse, Tag } from "antd";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";

import UmkSlider from "../../components/unimake-slider/UmkSlider";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const customPanelStyle = {
	background: "#f7f7f7",
	borderRadius: 4,
	marginBottom: 24,
	border: 0,
	overflow: "hidden",
};

class UnicoPage extends React.Component<any, any> {
	state = {
		SlideItens: [
			{
				image: require("../../assets/img/banners/auto_pecas.jpg"),
			},
			{
				image: require("../../assets/img/banners/varejo.jpg"),
			},
			{
				image: require("../../assets/img/banners/eletrico.jpg"),
			},
			{
				image: require("../../assets/img/banners/ferragens.jpg"),
			},
		],
		UnicoLogo: require("../../assets/img/products/unico.png"),
		loading: true,
	};

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		document.title = "Unimake Software - Único";
		this.handleLoading();
	}

	handleLoading() {
		setTimeout(() => {
			this.setState({ loading: false });
		}, 1000);
	}

	render() {
		return (
			<div>
				<section className="top-slider">
					<UmkSlider className="principal-slider" SlideItens={this.state.SlideItens} />
				</section>

				<section className="unimake-container">
					<div className="content-area">
						<Card loading={this.state.loading}>
							<Row>
								<Col md={3} sm={3}>
									<img src={this.state.UnicoLogo} />
								</Col>

								<Col md={16} sm={16}>
									<div className="produto-content-header">
										<h1 className="title product-title">Único Agro</h1>
										<span className="product-content">
											Sistema de Gestão Agroindustrial | Único AGRO integrado e específico para
											agroindústria.
										</span>
									</div>
								</Col>
							</Row>
						</Card>

						<br />

						<Card loading={this.state.loading}>
							<Tabs tabPosition="top" defaultActiveKey="1">
								<TabPane tab="Unico Agro" key="1">
									<h1 className="title">
										"O agronegócio evoluiu. Evolua também com o que há de melhor em gestão para sua
										fecularia com o Único ERP".
									</h1>

									<p>
										O Único é um software integrado de gestão (ERP) especializado no segmento de
										fecularias e farinheiras. A cada dia mais empresas descobrem as vantagens de ter
										um software totalmente voltado para suas necessidades. Aumente a competitividade
										e os lucros de sua empresa com quem há 15 anos mais entende de gestão no setor.
									</p>

									<p>
										Conheça as principais funcionalidades do Único que farão sua empresa dar um
										salto em eficiência:
									</p>

									<img
										style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
										src={require("../../assets/img/products/unico/img_fecularia_apresentacao.jpg")}
										alt=""
									/>

									<br />

									<p style={{ paddingRight: 5 }}>
										<img
											style={{ paddingRight: 3 }}
											src={require("../../assets/img/products/unico/unico_fecularia_number_1.png")}
											width={23}
											height={23}
										/>
										PRODUTORES
									</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Controle e planejamento da colheita:
											</span>{" "}
											Mantém cadastro das propriedades e áreas dos produtores para planejamento do
											recebimento dos produtos primários, evitando a permanência excessiva de
											caminhões no pátio da indústria aguardando a pesagem.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Controle de fila de caminhões:</span>{" "}
											Permite acompanhar o tempo de espera dos caminhões no pátio da indústria e
											seu tempo de descarga.
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>
										<img
											style={{ paddingRight: 3 }}
											src={require("../../assets/img/products/unico/unico_fecularia_number_2.png")}
											width={23}
											height={23}
										/>
										BALANÇA
									</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>Integração com a balança:</span>{" "}
											Captura automaticamente no momento da pesagem o peso bruto e a tara do
											caminhão.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Controle de previsão de rendimento:
											</span>{" "}
											Captura automaticamente um ou vários PSA (Peso Submerso na Água) para
											apuração do percentual de previsão de rendimento.
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>
										<img
											style={{ paddingRight: 3 }}
											src={require("../../assets/img/products/unico/unico_fecularia_number_3.png")}
											width={23}
											height={23}
										/>
										FÁBRICA
									</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>Ordem de produção:</span> Emissão de um
											comando indicando os volumes a serem produzidos, onde o ERP calcula as
											quantidades de produtos primários e matérias-primas utilizadas, podendo
											inclusive serem alteradas durante o processo. A produção pode ser programada
											partindo dos pedidos de venda ou produção para estoque.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Planejamento de produção:</span> De
											acordo com a programação da colheita, compras de produtos primários e
											previsão de rendimento, pode-se efetuar o planejamento diário, semanal,
											mensal ou anual da produção.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Controle de lotes:</span> Definição da
											numeração dos lotes dos produtos acabados e baixa dos lotes das
											matérias-primas utilizadas na produção. Permite e facilita a rastreabilidade
											dos produtos utilizados na composição dos produtos acabados.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Rendimento da indústria:</span>{" "}
											Apuração da previsão de rendimento dos produtos primários versus rendimento
											efetivo da indústria.
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>
										<img
											style={{ paddingRight: 3 }}
											src={require("../../assets/img/products/unico/unico_fecularia_number_4.png")}
											width={23}
											height={23}
										/>
										LABORATÓRIO
									</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Controle de análises laboratoriais:
											</span>{" "}
											Lançamento dos itens analisados das amostras retiradas dos lotes dos
											produtos acabados.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Impressão de laudos:</span> Das
											análises laboratoriais dos lotes comercializados.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Pesquisa das análises laboratoriais:
											</span>{" "}
											Localiza os lotes de produtos acabados com análises laboratoriais que
											atenderam uma exigência mínima nos resultados microbiológicos e
											físico-químicos.
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>
										<img
											style={{ paddingRight: 3 }}
											src={require("../../assets/img/products/unico/unico_fecularia_number_5.png")}
											width={23}
											height={23}
										/>
										ADMINISTRAÇÃO
									</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>Nota Fiscal Eletrônica:</span> Emissão
											do DANFE e do XML para os destinatários de forma automatizada.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Uma única NF-e para várias pesagens:
											</span>{" "}
											Diminui o número de lançamentos no contas a pagar e otimiza os pagamentos
											aos produtores.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Controle dos custos de produção:</span>{" "}
											Faz a apuração dos custos diretos e indiretos dos produtos acabados.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Controle de adiantamentos e pagamentos ao produtor:
											</span>{" "}
											Permite efetuar adiantamentos de pagamentos aos produtores que serão
											descontados no fechamento das contas a pagar.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Envio de arquivos de cobrança:</span>{" "}
											Permite enviar arquivos de cobrança bancária gerados pelo ERP
											eletronicamente em lote*.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Baixa automatizada de boletos bancários:
											</span>{" "}
											Integração com o banco para remessa e retorno de valores sem necessidade de
											nova digitação*.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												* Verifique disponibilidade do seu banco com nosso departamento
												comercial.
											</span>
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Fluxo de caixa:</span> Previsão de
											receitas e despesas e programação de investimentos de forma mais confiável.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Controle de adiantamento de viagens para motoristas:
											</span>{" "}
											Organiza e agiliza os acertos com motoristas.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Integração com Serasa:</span> Gera
											informações financeiras dos clientes ao Serasa.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Entrada de NF-e via B2B:</span> O
											módulo 'Business to Business" permite a importação das NF-e de fornecedores
											automaticamente pelo ERP sem necessidade de digitação, alimentando
											automaticamente os módulos de contas a pagar, estoque, contabilidade e
											outros módulos relacionados.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Pedidos de venda:</span> Além de ser
											utilizado pelo setor de produção paraimport UmkSlider from
											'./../../components/unimake-slider/UmkSlider'; determinar as quantidades a
											ser produzidas, o ÚNICO facilita o faturamento, utilizando os dados do
											pedido para faturar sem necessidade de nova digitação.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Análise de lucratividade:</span>{" "}
											Levantamento do lucro obtido sobre as vendas efetuadas.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Romaneios de cargas para logística de entrega:
											</span>{" "}
											Permite a programação por caminhão, dos pedidos de vendas ou notas a serem
											carregadas para entrega, além de permitir a análise automática do peso do
											caminhão com a quantidade que deveria ter sido carregada, buscando possíveis
											falhas no carregamento.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Contabilidade e Escrita Fiscal:</span>{" "}
											Totalmente integrados.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>
												SPED Fiscal. SPED PIS-COFINS, SPED ICMS e FCONT:
											</span>{" "}
											Totalmente integrados.
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>
										<img
											style={{ paddingRight: 3 }}
											src={require("../../assets/img/products/unico/unico_fecularia_number_6.png")}
											width={23}
											height={23}
										/>
										ALMOXARIFADO
									</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>Almoxarifado:</span> Controle total do
											estoque de matérias-primas, insumos e materiais para manutenção da
											indústria.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Compras gerais:</span> Administração da
											compra de insumos e materiais para a indústria.
										</li>
										<li>
											<span style={{ fontWeight: "bold" }}>Controle de estoque:</span> Controle
											total do estoque de produtos acabados.
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>OUTRAS FUNCIONALIDADES</p>
									<ul>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Integração com dispositivos móveis:
											</span>{" "}
											Integração entre o ÚNICO e dispositivos móveis para realização de pedidos,
											consultas de preços, consultas de posição financeira de clientes entre
											outras (sob demanda).
										</li>
									</ul>
									<p style={{ paddingRight: 5 }}>
										Faça o ÚNICO tornar-se parte do sucesso da sua empresa hoje mesmo!
										<br />
										<br />
										<Link to="/contato">Solicite uma demonstração</Link>, ou ligue para nosso
										departamento comercial pelo (44) 3423-4904.
									</p>
								</TabPane>
								<TabPane tab="Modúlos" key="2">
									<Collapse
										bordered={false}
										defaultActiveKey={["1"]}
										expandIcon={({ isActive }) => (
											<CaretRightOutlined rotate={isActive ? 90 : 0} />
										)}>
										<Panel header="Administrativo" key="1" style={customPanelStyle}>
											<p>
												<Tag className="product-tag" color="green">
													{" "}
													Cadastros{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Comunicação interna{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Agenda de contatos{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Agenda de compromissos{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Agenda telefônica{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Siga-me{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Relatórios{" "}
												</Tag>
											</p>
										</Panel>
										<Panel header="Financeiro" key="2" style={customPanelStyle}>
											<p>
												<Tag className="product-tag" color="green">
													{" "}
													Contas a pagar{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Contas a receber{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Contas correntes{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Controle de cheques{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Controle de comissão{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Fluxo de caixa{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Boletos{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Relatórios{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Análise de crédito{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Centro de custos{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Consulta Serasa{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Classe gerencial{" "}
												</Tag>
											</p>
										</Panel>
										<Panel header="Vendas" key="3" style={customPanelStyle}>
											<p>
												<Tag className="product-tag" color="green">
													{" "}
													Orçamento{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Pedido de venda{" "}
												</Tag>
												<Tag className="product-tag" color="orange">
													{" "}
													Força de Venda Mobile{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Precificação automatizada{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Nota de pesagem{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Ordem de serviço{" "}
												</Tag>
												<Tag className="product-tag" color="orange">
													{" "}
													Faturamento{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Emissão de NF-e{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Emissão de CC-e{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Emissão de CT-e{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Emissão de MDF-e{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Relatórios{" "}
												</Tag>
											</p>
										</Panel>
										<Panel header="Compras" key="4" style={customPanelStyle}>
											<p>
												<Tag className="product-tag" color="green">
													{" "}
													Pedido de compra{" "}
												</Tag>
												<Tag className="product-tag" color="orange">
													{" "}
													Cotação de compras{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Comparação de preço{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Importação de NF-e de compra{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Nota de compra{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Nota de pesagem{" "}
												</Tag>
											</p>
										</Panel>

										<Panel header="Estoque" key="5" style={customPanelStyle}>
											<p>
												<Tag className="product-tag" color="green">
													{" "}
													Transferência de produto{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Despacho de mercadorias{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Revaliação de estoque{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Contagem de estoque{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Localização de produto{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Controle de lotes{" "}
												</Tag>
												<Tag className="product-tag" color="green">
													{" "}
													Relatórios{" "}
												</Tag>
											</p>
										</Panel>

										<Panel header="Contabilidade" key="6" style={customPanelStyle}>
											<p>
												<Tag className="product-tag" color="green">
													{" "}
													Relatórios{" "}
												</Tag>
											</p>
										</Panel>
									</Collapse>
								</TabPane>
							</Tabs>
						</Card>
					</div>
				</section>
			</div>
		);
	}
}
export default UnicoPage;

import UnimakeApiService from "./UnimakeApiService";

export default class LicencaService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/Loja/Licenca';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    public async validateLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/ValidateLicencaByCliente`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async validateDesativarLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/ValidateDesativarLicenca`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async desativarLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Desativar`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    public async editarLicenca(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post(`${this.path}/Editar`, body, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}

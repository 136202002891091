import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

class UmkRightButton extends React.Component<any, any> {
	props;

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	render() {
		return (
			<>
				<Button className="umk-slider-button" style={{ right: 0 }} onClick={this.props.onClick}>
					<RightOutlined />
				</Button>
			</>
		);
	}
}

export default UmkRightButton;

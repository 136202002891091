import React from "react";
import UmkCarrinho from "./../../components/unimake-carrinho/UmkCarrinho";

class CarrinhoPage extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		document.title = "Unimake Software - Carrinho";
	}

	render() {
		return (
			<section className="unimake-container unimake-content">
				<UmkCarrinho />
			</section>
		);
	}
}
export default CarrinhoPage;

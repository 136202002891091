import React from "react";
import { Col, Radio, Row } from "antd";
import produtos from "./../../variables/produtos";
import IProdutoLoja from "../../contract/IProdutoLoja";
import UmkProduto from "./../../components/v3/unimake-produto/UmkProduto";
import { Link } from "react-router-dom";

const options = [
	{ label: "Todos", value: "Todos" },
	{ label: "Agronegócio", value: "Agronegocio" },
	{ label: "Varejo", value: "Varejo" },
	{ label: "Documentos Fiscais", value: "DocumentosFiscais" },
	{ label: "Backup", value: "Backup" },
];

class ProdutosPage extends React.Component<any, any> {
	state = {
		filter: "Todos",
		produtos: [],
		sourceProdutos: [],
		loading: false,
	};

	constructor(props: any) {
		super(props);

		this.onChange4 = this.onChange4.bind(this);
	}

	componentDidMount() {
		document.title = "Unimake Software - Produtos";
		this.setState({
			produtos: produtos,
			sourceProdutos: produtos,
		});
	}

	onChange4 = (e) => {
		let filtro = e.target.value;

		this.setState({
			filter: filtro,
			loading: true,
		});

		if (filtro == "Todos") {
			this.setState({
				produtos: this.state.sourceProdutos,
			});
		} else {
			let newProdutos = [];
			let produtos = this.state.sourceProdutos;

			produtos.forEach((el) => {
				let result = this.filterProdutos(el, filtro);

				if (result) newProdutos.push(result);
			});

			this.setState({
				produtos: newProdutos,
			});
		}

		this.setState({
			filter: filtro,
			loading: false,
		});
	};

	filterProdutos(produto: IProdutoLoja, filter: String) {
		if (produto.Categorias) {
			if (produto.Categorias.includes(filter)) {
				return produto;
			}
		}
	}

	render() {
		return (
			<section className="unimake-area-v3">
				<div className="unimake-container">
					<Row>
						<Col sm={24} md={24}>
							<h1 className="ant-typography">Produtos</h1>
						</Col>
					</Row>
					<Row>
						<Col md={24} sm={24} xs={24}>
							<Radio.Group
								options={options}
								onChange={this.onChange4}
								value={this.state.filter}
								optionType="button"
								buttonStyle="solid"
								size="large"
							/>
						</Col>
					</Row>
					<Row>
						{this.state.produtos.map((item: IProdutoLoja) => (
							<Col md={4} sm={8} xs={24}>
								<UmkProduto Produto={item} />
							</Col>
						))}
					</Row>
				</div>
			</section>
		);
	}
}
export default ProdutosPage;

import React from "react";

import { Row, Col, Radio } from "antd";
import "./UmkSlide.scss";

class UmkSlide extends React.Component<any, any> {
	defaultProps = {
		Slides: [],
	};

	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<Row>
				{this.props.Slides.map((item) => (
					<Col md={4} sm={8} xs={12}>
						<div className="image-container">
							<img
								className="card-produto-v3"
								style={{ display: "flex" }}
								alt={item.Caption}
								src={item.Image}
							/>
						</div>
					</Col>
				))}
			</Row>
		);
	}
}
export default UmkSlide;

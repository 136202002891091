import React from "react";
import { Col, Divider, Row, Typography } from "antd";
import UmkEsqueciSenha from "../../components/unimake-esquecisenha/UmkEsqueciSenha";

const { Title } = Typography;

class EsqueciMinhaSenhaClientePage extends React.Component<any, any> {
	state = {
		loading: false,
	};

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		document.title = "Unimake Software - Cliente - Esqueci Minha Senha";
	}

	render() {
		return (
			<div>
				<section className="unimake-container">
					<div className="content-area">
						<Row>
							<Col sm={24} md={24} xs={24}>
								<div className="content-area">
									<Title level={4}>Esqueci minha senha</Title>
									<Title level={5}>Informe seu CNPJ ou CPF e enviaremos um link com instruções de recuperação para seu e-mail.</Title>
									<Divider />
									<UmkEsqueciSenha tipo="cliente" />
								</div>
							</Col>
						</Row>
					</div>
				</section>
			</div>
		);
	}
}
export default EsqueciMinhaSenhaClientePage;

import ApiRequestService from './ApiRequestService';
import UnimakeApiService from './UnimakeApiService';

//TODO: Não tem envio de contato na nova API
export default class ContatoService {
    private unimakeApi: UnimakeApiService;
    private path: string = '/free/Contato';

    constructor() {
        this.unimakeApi = new UnimakeApiService();
    }

    
    public async SendMail(body: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this.unimakeApi.post(`${this.path}/Site`, body);

                resolve(response);
            }
            catch(error) {
                reject(error);
            }
        });
    }
}
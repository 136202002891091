import React from "react";

import { Form, Row, Col, Input, Select, Button, Spin } from "antd";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";

import IContatoForm from "../../../contract/form/IContatoForm";
import ContatoService from "../../../services/ContatoService";
import IResponse from "../../../contract/IResponse";
import NotificationUtil from "./../../../utils/NotificationUtil";
import ModalUltil from "./../../../utils/ModalUtil";
import { FormInstance } from "antd/lib/form";
import DynamicPhone from "../../../hooks/DynamicPhone";

const { Option } = Select;
const { TextArea } = Input;

class UmkContato extends React.Component<any, any> {
	formRef = React.createRef<FormInstance>();

	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private ContatoService: ContatoService;

	state = {
		loading: false,
		departamento: "",
	};

	constructor(props: any) {
		super(props);

		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.ContatoService = new ContatoService();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.sendMensage = this.sendMensage.bind(this);
	}

	handleChange(value: any) {
		this.formRef.current.setFieldsValue({ departamento: value });
		this.setState({
			departamento: value,
		});
	}

	handleSubmit = (values: IContatoForm) => {
		let body = {
			nome: values.nome,
			email: values.email,
			empresa: values.empresa,
			assunto: values.assunto,
			mensagem: values.mensagem,
			departamento: values.departamento,
			telefone: values.telefone,
		};

		this.sendMensage(body);
	};

	async sendMensage(body: any) {
		this.setState({ loading: true });

		try {
			let result = await this.ContatoService.SendMail(body);

			this.setState({ loading: false });

			let response: IResponse = result.data;

			if (response.success) {
				this.ModalUltil.Success((response.message = response.message ? response.message : "Email Enviado!"));
				this.formRef.current.resetFields();
			} else this.ModalUltil.Error(response.message);
		} catch (error) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		return (
			<div>
				<Spin tip="Aguarde..." spinning={this.state.loading}>
					<Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
						<Row>
							<Col xs={24} sm={24} md={24}>
								<Form.Item
									className="form-item-umk"
									name="nome"
									rules={[{ required: true, message: "O campo Nome é obrigatório!" }]}
									label="Nome">
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col xs={24} sm={12} md={12}>
								<Form.Item
									className="form-item-umk"
									name="email"
									rules={[{ required: true, message: "O campo E-mail é obrigatório!" }]}
									label="E-mail">
									<Input />
								</Form.Item>
							</Col>

							<Col sm={1} md={1} />

							<Col xs={24} sm={11} md={11}>
								<Form.Item
									className="form-item-umk"
									name="empresa"
									rules={[{ required: true, message: "O campo Empresa é obrigatório!" }]}
									label="Empresa">
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col xs={24} sm={24} md={24}>
								<Form.Item
									className="form-item-umk"
									name="departamento"
									rules={[{ required: true, message: "O campo Departamento é obrigatório!" }]}
									label="Departamento">
									<Select onChange={this.handleChange} placeholder="Selecione">
										<Option value="Suporte">Suporte a produtos</Option>
										<Option value="Comercial">
											Comercial (orçamentos, vendas, dúvidas sobre valores, etc...)
										</Option>
										<Option value="Financeiro">Financeiro ou Administrativo</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col xs={24} sm={12} md={12}>
								<Form.Item
									className="form-item-umk"
									name="telefone"
									rules={[{ required: true, message: "O campo Telefone é obrigatório!" }]}
									label="Telefone">
									<DynamicPhone />
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col xs={24} sm={24} md={24}>
								<Form.Item
									className="form-item-umk"
									name="assunto"
									rules={[{ required: true, message: "O campo Assunto é obrigatório!" }]}
									label="Assunto">
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col xs={24} sm={24} md={24}>
								<Form.Item
									className="form-item-umk"
									name="mensagem"
									rules={[{ required: true, message: "O campo Mensagem é obrigatório!" }]}
									label="Mensagem">
									<TextArea rows={4} />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item>
							<Button type="primary" block htmlType="submit" className="login-form-button">
								Enviar
							</Button>
						</Form.Item>
					</Form>
				</Spin>
			</div>
		);
	}
}
export default UmkContato;

import React from "react";
import { Carousel } from "antd";

class UmkSlider extends React.Component<any, any> {
	static defaultProps = {
		SlideItens: [],
		speed: 3000,
	};

	render() {
		return (
			<div className="main-slider">
				<Carousel autoplaySpeed={this.props.speed} autoplay={true} effect="fade">
					{this.props.SlideItens.map((item: any) => (
						<div className="unimake-slide-wrapper" key={item.image}>
							<img className="slick-image slide-principal unimake-img" src={item.image} />{" "}
							<h3 className="slick-caption"> {item.caption} </h3>{" "}
						</div>
					))}
				</Carousel>
			</div>
		);
	}
}
export default UmkSlider;

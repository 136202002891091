import React from "react";
import { Button, Select } from "antd";
import ProdutoUtils from "./../../utils/ProdutoUtil";
import CartService from "../../services/Loja/CartService";
import IResponse from "../../contract/IResponse";
import NotificationUtil from "./../../utils/NotificationUtil";
import ModalUltil from "./../../utils/ModalUtil";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import CommonUtil from "../../utils/CommonUtil";

const { Option } = Select;

class UmkCarrinhoRow extends React.Component<any, any> {
	private ProdutoUtils: ProdutoUtils;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private CartService: CartService;
	private Common: CommonUtil;

	defaulProps = {
		updateCart: undefined,
		setLoading: undefined,
		unsetLoading: undefined,
		Item: {
			anuidades: 0,
			idproduto: 0,
			nome: "",
			preco: "",
			descontos: undefined,
			total: undefined,
			subtotal: undefined,
			quantidade: 0,
		},
	};

	state = {
		id: 0,
		diretorioIcon: "../../assets/img/error.png",
		contratoHtml: "",
		quantidade: 0,
		validade: 0,
		loading: false,
	};

	constructor(props: any) {
		super(props);

		this.ProdutoUtils = new ProdutoUtils();
		this.CartService = new CartService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.Common = new CommonUtil();

		this.getIcon = this.getIcon.bind(this);
		this.getContratoHtml = this.getContratoHtml.bind(this);
		this.handleQuantidade = this.handleQuantidade.bind(this);
		this.validadeChange = this.validadeChange.bind(this);
		this.removeProduct = this.removeProduct.bind(this);
	}

	componentDidMount() {
		this.setState({
			id: this.props.Item.idproduto,
			quantidade: this.props.Item.quantidade,
		});

		this.getIcon(this.props.Item.idproduto);
		this.getContratoHtml(this.props.Item.idproduto);
	}

	async validadeChange(value) {
		try {
			this.props.setLoading();

			let validade = parseInt(value);

			let result = await this.CartService.SetAnuidade(validade, this.state.id);
			let response: IResponse = result.data;

			if (response.success) {
				this.ModalUltil.Success("Validade alterada!");
				await this.props.updateCart();
			} else {
				this.ModalUltil.Error("Houve um erro ao alterar a validade");
			}

			this.props.unsetLoading();
		} catch (error) {
			this.props.unsetLoading();
			this.ModalUltil.Exception(error);
		}
	}

	getIcon(ProdutoID: any) {
		let diretorio = this.ProdutoUtils.getIcon(ProdutoID);

		this.setState({
			diretorioIcon: `${diretorio}`,
		});
	}

	getContratoHtml(ProdutoID: any) {
		let contratoHtml = this.ProdutoUtils.getContratoHtml(ProdutoID);

		this.setState({
			contratoHtml: contratoHtml,
		});
	}

	async removeProduct() {
		try {
			this.props.setLoading();

			let result = await this.CartService.RemoveProduct(this.state.id);
			let response: IResponse = result.data;

			if (response.success) {
				await this.props.updateCart();
				this.ModalUltil.Success("Produto removido!");

				if (response.data == true) {
					localStorage.removeItem("OrderID");
				}
			} else {
				this.ModalUltil.Exception(response.message);
			}

			this.props.unsetLoading();
		} catch (error) {
			this.props.unsetLoading();
			this.ModalUltil.Exception(error);
		}
	}

	async handleQuantidade(type: string) {
		try {
			switch (type) {
				case "menos":
					if (this.state.quantidade > 0) {
						let quantidade = this.state.quantidade;
						quantidade--;

						this.setState({
							quantidade: quantidade,
						});

						this.props.setLoading();

						let result = await this.CartService.SetQuantidade(quantidade, this.state.id);
						let response: IResponse = result.data;

						if (response.success) {
							await this.props.updateCart();
						} else {
							this.ModalUltil.Exception(response.message);
						}

						this.props.unsetLoading();
					}
					break;

				case "mais":
					if (this.state.quantidade < 100) {
						let quantidade = this.state.quantidade;
						quantidade++;

						this.setState({
							quantidade: quantidade,
						});
						this.props.setLoading();

						let result = await this.CartService.SetQuantidade(quantidade, this.state.id);
						let response: IResponse = result.data;

						if (response.success) {
							await this.props.updateCart();
						} else {
							this.ModalUltil.Exception(response.message);
						}

						this.props.unsetLoading();
					}
					break;
			}
		} catch (error) {
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		const { props, state } = this;
		return (
			<>
				<tr>
					<td>
						<div className="img-container">
							<img style={{ maxWidth: 60 }} src={this.state.diretorioIcon} alt="..." />
						</div>
					</td>

					<td className="td-name">
						<span>
							{props.Item.nome}{" "}
							<span dangerouslySetInnerHTML={{ __html: this.state.contratoHtml }}></span>
						</span>
					</td>

					<td>
						{state.quantidade}
						<div className="btn-group mb-1">
							<Button
								className="unimake-minor-button"
								size="small"
								onClick={() => {
									this.handleQuantidade("menos");
								}}
								icon={<MinusOutlined />}></Button>
							<Button
								className="unimake-minor-button"
								size="small"
								onClick={() => {
									this.handleQuantidade("mais");
								}}
								icon={<PlusOutlined />}></Button>
						</div>
					</td>

					<td>
						<Select
							value={props.Item.anuidades}
							style={{ width: 100 }}
							onChange={this.validadeChange}
							placeholder="Selecione">
							<Option value="1">1 ano</Option>
							<Option value="2">2 anos</Option>
							<Option value="3">3 anos</Option>
							<Option value="4">4 anos</Option>
							<Option value="5">5 anos</Option>
							<Option value="6">6 anos</Option>
							<Option value="7">7 anos</Option>
							<Option value="8">8 anos</Option>
							<Option value="9">9 anos</Option>
							<Option value="10">10 anos</Option>
						</Select>
					</td>

					<td>{this.Common.FormatBrl(props.Item.preco)}</td>

					<td>{props.Item.subtotal
						? this.Common.FormatBrl(props.Item.subtotal)
						: this.Common.FormatBrl((props.Item.preco * props.Item.quantidade))}</td>

					<td>{props.Item.descontos 
					? this.Common.FormatBrl(props.Item.descontos)
					: this.Common.FormatBrl(0)}</td>

					<td>{props.Item.total 
					? this.Common.FormatBrl(props.Item.total)
					: this.Common.FormatBrl(props.Item.preco)}</td>

					{/* TODO: Verificar sobre a renovação neste ponto faturamento.php ln: 347 */}
					<td className="td-actions">
						<Button
							onClick={() => {
								this.removeProduct();
							}}
							className="unimake-red-button"
							icon={<DeleteOutlined />}></Button>
					</td>
				</tr>
			</>
		);
	}
}
export default UmkCarrinhoRow;

import { notification } from "antd";

export default class NotificationUtil {
	public CreateNotification(Title: string, Text: string): void {
		notification.open({
			message: Title,
			description: Text,
			duration: 2,
			placement: "bottomLeft",
		});
	}

	public Error(Text: string) {
		console.log(Text);

		notification.open({
			message: "Erro",
			description: Text,
			duration: 5,
			placement: "bottomLeft",
			type: "error",
		});
	}

	public Exception(exception: any) {
		console.log(exception);

		let message: string = "";

		if (exception.message) message = exception.message;
		else message = exception;

		notification.open({
			message: "Erro",
			description: message,
			duration: 5,
			placement: "bottomLeft",
			type: "error",
		});
	}

	public Info(message: string) {
		notification.open({
			message: "Informação",
			description: message,
			duration: 10,
			placement: "bottomLeft",
			type: "info",
		});
	}

	public Success(message: string) {
		notification.open({
			message: "Ok!",
			description: message,
			duration: 5,
			placement: "bottomLeft",
			type: "success",
		});
	}

	public Warning(message: string) {
		notification.open({
			message: "Atenção!",
			description: message,
			duration: 10,
			placement: "bottomLeft",
			type: "warning",
		});
	}
}

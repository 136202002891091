import React from "react";
import { Button, Card, Col, DatePicker, Divider, Form, Input, Row, Select, Spin, Tabs, Typography } from "antd";
import TrabalheConoscoService from "./../../services/TrabalheConoscoService";
import NotificationUtil from "../../utils/NotificationUtil";
import ModalUltil from "./../../utils/ModalUtil";
import CommonUtil from "./../../utils/CommonUtil";
import IResponse from "../../contract/IResponse";
import { MaskedInput } from "antd-mask-input";
import { FormInstance } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import UmkEndereco from "../../components/v3/unimake-endereco/UmkEndereco";

import { cpf } from "cpf-cnpj-validator";
import ptBR from "antd/es/date-picker/locale/pt_BR";
import DynamicPhone from "../../hooks/DynamicPhone";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

class CurriculoPage extends React.Component<any, any> {
	formRef = React.createRef<FormInstance>();

	state = {
		loading: false,
		redirect: false,
		redirectPage: "",
		sexo: "",
		estado_civil: "",
	};

	private Common: CommonUtil;
	private NotificationService: NotificationUtil;
	private ModalUltil: ModalUltil;
	private TrabalheConoscoService: TrabalheConoscoService;

	constructor(props: any) {
		super(props);

		this.Common = new CommonUtil();
		this.TrabalheConoscoService = new TrabalheConoscoService();
		this.NotificationService = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);

		this.handleChangeSexo = this.handleChangeSexo.bind(this);
		this.handleChangeEstadoCivil = this.handleChangeEstadoCivil.bind(this);
	}

	componentDidMount() {
		document.title = "Unimake Software - Trabalhe Conosco";
	}

	handleChangeEstadoCivil(value: any) {
		this.formRef.current.setFieldsValue({ estado_civil: value });
		this.setState({
			estado_civil: value,
		});
	}

	handleChangeSexo(value: any) {
		this.formRef.current.setFieldsValue({ sexo: value });
		this.setState({
			sexo: value,
		});
	}

	handleSubmit = async (values: any) => {
		try {
			this.setState({ loading: true });

			let result = await this.TrabalheConoscoService.Create(values);

			this.setState({ loading: false });

			let response: IResponse = result.data;

			if (response.success) {
				this.NotificationService.Success("Cadastro Realizado");

				this.setState({
					redirect: true,
					redirectPage: `${process.env.PUBLIC_URL}/trabalhe-conosco`,
				});
			} else this.ModalUltil.Error(response.message);
		} catch (error) {
			this.ModalUltil.Error(error);
			this.setState({
				loading: false,
			});
		}
	};

	render() {
		return (
			<div>
				<section className="unimake-container unimake-content">
					<Title level={1}>Trabalhe Conosco</Title>
					<Spin tip="Aguarde..." spinning={this.state.loading}>
						<Form
							name="control-ref"
							ref={this.formRef}
							layout="vertical"
							// onFinish={this.handleSubmit}
							className="login-form">
							<Tabs defaultActiveKey="1">
								<TabPane tab="Dados Pessoais" key="1">
									<Card title="Dados Pessoais">
										<Row justify="space-between">
											<Col sm={14} md={14}>
												<Form.Item
													label="Nome"
													className="form-item-umk"
													name="nome"
													rules={[
														{ required: true, message: "O campo Nome é requerido." },
														{ max: 100, message: "Máximo 100 caracteres" },
													]}>
													<Input type="text" />
												</Form.Item>
											</Col>

											<Col sm={4} md={4}>
												<Form.Item
													label="RG"
													className="form-item-umk"
													name="rg"
													rules={[
														{ required: true, message: "O campo RG é requerido." },
														{ max: 16, message: "Informe RG válido!" },
													]}>
													<Input type="text" />
												</Form.Item>
											</Col>

											<Col sm={4} md={4}>
												<Form.Item
													label="CPF"
													className="form-item-umk"
													name="cpf"
													rules={[
														{ required: true, message: "O campo CPF é requerido." },
														{ len: 14, message: "Informe um CPF válido!" },
														({ getFieldValue }) => ({
															validator(_, value) {
																if (value === "") {
																	return Promise.resolve();
																}

																if (cpf.isValid(value)) {
																	return Promise.resolve();
																} else {
																	return Promise.reject(
																		new Error("Informe um CPF válido")
																	);
																}
															},
														}),
													]}>
													<MaskedInput mask="000.000.000-00" />
												</Form.Item>
											</Col>
										</Row>

										<Row justify="space-between">
											<Col sm={5} md={6}>
												<Form.Item
													className="form-item-umk"
													name="sexo"
													rules={[{ required: true, message: "O campo Sexo é requerido." }]}
													label="Sexo">
													<Select onChange={this.handleChangeSexo} placeholder="Selecione">
														<Option value="masculino">Masculino</Option>
														<Option value="femnino">Feminino</Option>
													</Select>
												</Form.Item>
											</Col>

											<Col sm={5} md={6}>
												<Form.Item
													className="form-item-umk"
													name="estado_civil"
													rules={[
														{
															required: true,
															message: "O campo Estado Civil é requerido.",
														},
													]}
													label="Estado Civil">
													<Select
														onChange={this.handleChangeEstadoCivil}
														placeholder="Selecione">
														<Option value="solteiro">Solteiro (a)</Option>
														<Option value="casado">Casado (a)</Option>
														<Option value="divorciado">Divorciado (a)</Option>
														<Option value="uniaoestavel">União Estável</Option>
													</Select>
												</Form.Item>
											</Col>

											<Col sm={5} md={4}>
												<Form.Item
													label="Data de Nascimento"
													className="form-item-umk"
													name="data_nascimento"
													rules={[
														{
															required: true,
															message: "O campo Data de Nascimento é requerido.",
														},
														{ type: "date", message: "Forneça uma data válida." },
													]}>
													<DatePicker format={"DD/MM/YYYY"} locale={ptBR} showToday={false} />
												</Form.Item>
											</Col>

											<Col sm={5} md={6}>
												<Form.Item
													label="Naturalidade"
													className="form-item-umk"
													name="naturalidade"
													rules={[
														{
															required: true,
															message: "O campo Naturalidade é requerido.",
														},
														{ max: 30, message: "Máximo 30 caracteres." },
													]}>
													<Input type="text" />
												</Form.Item>
											</Col>
										</Row>
									</Card>

									<br />

									<UmkEndereco />

									<br />

									<Card title="Detalhes">
										<Row justify="space-between">
											<Col sm={24} md={11}>
												<Form.Item
													name="area_interesse"
													label="Área de Interesse"
													rules={[{ max: 30, message: "Máximo 30 caracteres." }]}>
													<Input type="text" />
												</Form.Item>
											</Col>

											<Col sm={24} md={12}>
												<Form.Item
													label="Unidade"
													name="unidade_trabalho"
													rules={[{ max: 30, message: "Máximo 30 caracteres." }]}>
													<Input type="text" />
												</Form.Item>
											</Col>
										</Row>
									</Card>

									<br />

									<Card title="Contato">
										<Row justify="space-between">
											<Col sm={3} md={5}>
												<Form.Item name="telefone_residencial" label="Telefone">
													<DynamicPhone />
												</Form.Item>
											</Col>

											<Col sm={3} md={5}>
												<Form.Item label="Celular" name="telefone_celular">
													<DynamicPhone />
												</Form.Item>
											</Col>

											<Col sm={3} md={12}>
												<Form.Item
													label="Email"
													name="email"
													rules={[
														{ required: true, message: "O campo Email é requerido." },
														{ type: "email", message: "Informe um e-mail válido." },
													]}>
													<Input type="email" />
												</Form.Item>
											</Col>
										</Row>

										<Row justify="space-between">
											<Col sm={3} md={8}>
												<Form.Item
													label="Twitter"
													name="twitter"
													rules={[
														{
															type: "url",
															message: "Informe um endereço de Internet válido",
														},
													]}>
													<Input type="url" />
												</Form.Item>
											</Col>

											<Col sm={3} md={8}>
												<Form.Item
													label="Facebook"
													name="facebook"
													rules={[
														{
															type: "url",
															message: "Informe um endereço de Internet válido",
														},
													]}>
													<Input type="url" />
												</Form.Item>
											</Col>

											<Col sm={3} md={7}>
												<Form.Item
													label="Site ou Blog Pessoal"
													name="site"
													rules={[
														{ type: "url", message: "Informe um endereço de blog válido" },
													]}>
													<Input type="url" />
												</Form.Item>
											</Col>
										</Row>
									</Card>

									<Divider />
								</TabPane>

								<TabPane tab="Formação Acadêmica" key="2">
									<Row justify="space-between">
										<Col sm={5} md={5}>
											<Form.Item label="Curso">
												<Input type="text" />
											</Form.Item>
										</Col>

										<Col sm={5} md={5}>
											<Form.Item label="Instituição">
												<Input type="text" />
											</Form.Item>
										</Col>

										<Col sm={5} md={5}>
											<Form.Item label="Nível Acadêmico">
												<Input type="text" />
											</Form.Item>
										</Col>

										<Col sm={5} md={5}>
											<Form.Item label="Ano de Conclusão">
												<Input type="text" />
											</Form.Item>
										</Col>
									</Row>
								</TabPane>

								<TabPane tab="Idiomas" key="3">
									<Row justify="space-between">
										<Col sm={5} md={5}>
											<Form.Item label="Inglês">
												<Input type="text" />
											</Form.Item>
										</Col>

										<Col sm={5} md={5}>
											<Form.Item label="Espanhol">
												<Input type="text" />
											</Form.Item>
										</Col>

										<Col sm={5} md={5}>
											<Form.Item label="Outro">
												<Input type="text" />
											</Form.Item>
										</Col>
									</Row>
								</TabPane>

								<TabPane tab="Conhecimentos" key="4">
									<Row justify="space-between">
										<Col sm={24} md={24}>
											<Form.Item label="Conhecimentos Técnicos/Gerais">
												<TextArea rows={10} title="Conhecimentos Técnicos/Gerais"></TextArea>
											</Form.Item>
										</Col>
									</Row>
								</TabPane>

								<TabPane tab="Experiência Profissional" key="5">
									<Col sm={24} md={24}>
										<Form.Item label="Experiência Profissional">
											<TextArea rows={10} title="Experiência Profissional"></TextArea>
										</Form.Item>
									</Col>
								</TabPane>

								<TabPane tab="Senha" key="6">
									<Row justify="space-between">
										<Col sm={8} md={8}>
											<Form.Item
												label="Senha"
												name="senha"
												rules={[
													{ required: true, message: "O campo Senha é obrigatório!" },
													{ max: 50, message: "Informe uma senha com até 50 caracteres" },
												]}>
												<Input name="senha" type="password" />
											</Form.Item>
										</Col>

										<Col sm={8} md={8}>
											<Form.Item
												label="Confirmar Senha"
												name="confsenha"
												rules={[
													{
														required: true,
														message: "O campo Confirmar Senha é obrigatório!",
													},
													{ max: 50, message: "Informe uma senha com até 50 caracteres" },
													({ getFieldValue }) => ({
														validator(_, value) {
															if (!value || getFieldValue("senha") === value) {
																return Promise.resolve();
															}
															return Promise.reject(
																new Error("As duas senhas não são iguais!")
															);
														},
													}),
												]}
												dependencies={["senha"]}>
												<Input type="password" />
											</Form.Item>
										</Col>

										<Col md={7} className="hidden-xs hidden-sm"></Col>
									</Row>
								</TabPane>
							</Tabs>

							<br />
							<Form.Item>
								<Button block type="primary" htmlType="submit" className="login-form-button">
									Salvar
								</Button>
							</Form.Item>
						</Form>
					</Spin>
				</section>
			</div>
		);
	}
}
export default CurriculoPage;

import StorageService from './StorageService';
import UnimakeApiService from './UnimakeApiService';

export default class AuthenticationService {
    private unimakeApi: UnimakeApiService;
    private storage: any;

    constructor() {
        this.storage = new StorageService();
        this.unimakeApi = new UnimakeApiService();
    }

    public async CheckAuth(): Promise<boolean> {
        let usuario = this.storage.Get('Usuario');

        if (!usuario)
            return false;

        usuario = JSON.parse(usuario);

        if (!usuario)
            return false;
        else
            return true;
    }


    public async ValidateToken(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = {
                    'Authorization': localStorage.getItem('TokenUsuario')
                }
                let response = await this.unimakeApi.post('/free/Loja/Auth/Validate', null, headers);

                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }

}
import React from "react";
import IProdutoLoja from "./../../../contract/IProdutoLoja";
import ProdutoService from "./../../../services/Loja/ProdutoService";
import IResponse from "./../../../contract/IResponse";
import { Button, Col, Row, Tooltip } from "antd";
import NotificationUtil from "./../../../utils/NotificationUtil";
import ClienteService from "./../../../services/ClienteService";
import PedidoService from "./../../../services/Loja/PedidoService";
import CookieUtil from "./../../../utils/CookieUtil";
import { updateQuantity } from "../../../actions/CartActions";
import ModalUltil from "./../../../utils/ModalUtil";
import { Redirect, Link } from "react-router-dom";
import { orange, blue, green } from "@ant-design/colors";

class UmkProduto extends React.Component<any, any> {
	private ProdutoService: ProdutoService;
	private ClienteService: ClienteService;
	private PedidoService: PedidoService;

	private NotificationUtil: NotificationUtil;
	private ModalUltil: ModalUltil;
	private CookieUtil: CookieUtil;

	static defaultProps = {
		Produto: undefined,
	};

	state = {
		preco: "Carregando...",
		PedidoId: undefined,
		loading: false,
		redirect: false,
		redirectPage: undefined,
	};

	constructor(props: any) {
		super(props);

		this.ProdutoService = new ProdutoService();
		this.ClienteService = new ClienteService();
		this.PedidoService = new PedidoService();

		this.NotificationUtil = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.CookieUtil = new CookieUtil();

		this.initialize = this.initialize.bind(this);
		this.setPrice = this.setPrice.bind(this);
		this.comprar = this.comprar.bind(this);
		this.openUrl = this.openUrl.bind(this);
	}

	componentDidMount() {
		this.setState({
			img: this.props.img,
			colorHex: this.props.colorHex,
			routePath: this.props.routePath,
			content: this.props.content,
			title: this.props.title,
			buttonText: this.props.buttonText,
			buttonRoute: this.props.buttonRoute,
		});

		this.initialize();
	}

	initialize() {
		if (this.props.Produto.Buy) {
			this.setPrice();
		}
	}

	async setPrice() {
		this.ProdutoService.GetPreco(this.props.Produto.ID)
			.then((result) => {
				let response: IResponse = result.data;

				if (response.success) {
					let preco: any = response.data;

					preco = preco.preco ? preco.preco : "Preço não encontrado. recarregue a página novamente";

					let label = this.props.Produto.Td1Text;
					let newText = label.replace("#preco#", preco);

					this.setState({
						preco: newText,
					});
				}
			})
			.catch((err) => {
				this.NotificationUtil.Exception(err);
			});
	}

	async comprar(ID: any) {
		try {
			let cliente = this.ClienteService.GetClienteLogado();
			this.setState({ loading: true });

			this.PedidoService.Comprar(ID, 1, cliente ? cliente.idcliente : null)
				.then((result) => {
					let response: IResponse = result.data;

					//TODO: Verificar sobre o segundo pedido
					if (response.success) {
						let id = response.data.IDPedido;

						this.setState({
							PedidoId: id,
						});

						this.CookieUtil.Set("OrderID", id);
						localStorage.setItem("OrderID", id);

						updateQuantity(1);

						this.ModalUltil.SuccessCustomTitle("Produto adicionado ao carrinho!", "Você está a poucos passos de adquirir uma solução de sucesso!");
					} else {
						this.ModalUltil.Exception(
							response.message ? response.message : "Houve um erro ao adicionar o produto"
						);
					}

					this.setState({ loading: false });
				})
				.catch((err) => {
					this.setState({ loading: false });
					this.ModalUltil.Exception(err);
				});
		} catch (err) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(err);
		}
	}

	openUrl() {
		try {
			let url: string = this.props.Produto.SaibaMais;

			if (url.includes("gemini") || url.includes("danfeview")) {
				window.location.href = this.props.Produto.SaibaMais;
			} else {
				this.setState({
					redirect: true,
					redirectPage: url,
				});
			}
		} catch (error) {
			this.ModalUltil.Exception(error);
		}
	}

	render() {
		let produto: IProdutoLoja = this.props.Produto;

		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<div
				style={{
					display: "block",
					marginLeft: "auto",
					marginRight: "auto",
					marginBottom: 10,
					marginTop: 10,
					maxWidth: 130,
				}}>
				<Tooltip title={produto.Text} placement="bottom">
					<div className="image-container">
						<div className="titulo-produto">
							<span>{produto.Titulo}</span>
						</div>

						<img
							className="card-produto-v3"
							style={{ display: "flex", height: "130px", width: "130px" }}
							src={produto.Image}
						/>

						<div className="card-produto-background-v3">
							<div className="card-produto-shadow-area-v3">
								<div className="card-produto-footer-v3">
									<div>
										<Row>
											<Col xs={24} style={{ marginBottom: "5px" }}>
												{(produto.LinkExterno && (
													<Button
														className="produto-saiba-mais"
														href={produto.SaibaMais}
														block
														type="primary"
														style={{ backgroundColor: orange[6], border: "none" }}
														shape="round">
														Saiba Mais
													</Button>
												)) || (
													<Link to={produto.SaibaMais}>
														<Button
															className="produto-saiba-mais"
															block
															type="primary"
															style={{ backgroundColor: orange[6], border: "none" }}
															shape="round">
															Saiba Mais
														</Button>
													</Link>
												)}
											</Col>
											{produto.Buy ? (
												<Col xs={24}>
													<Button
														className="produto-comprar"
														type="primary"
														block
														style={{ backgroundColor: green[8], border: "none" }}
														loading={this.state.loading}
														shape="round"
														onClick={() => {
															this.comprar(produto.ID);
														}}>
														Comprar
													</Button>
												</Col>
											) : (
												<>
													{produto.Download ? (
														<Col xs={24}>
															{(produto.LinkExterno && (
																<Button
																	className="produto-download"
																	href={produto.SaibaMais}
																	block
																	type="primary"
																	style={{ backgroundColor: blue[8], border: "none" }}
																	shape="round">
																	Download
																</Button>
															)) || (
																<Link to={produto.SaibaMais}>
																	<Button
																		className="produto-download"
																		block
																		type="primary"
																		style={{
																			backgroundColor: blue[8],
																			border: "none",
																		}}
																		shape="round">
																		Download
																	</Button>
																</Link>
															)}
														</Col>
													) : (
														<></>
													)}
												</>
											)}
										</Row>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Tooltip>
			</div>
		);
	}
}
export default UmkProduto;

import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

class UmkBlogLeftButton extends React.Component<any, any> {
	props;

	constructor(props: any) {
		super(props);
		this.props = props;
	}

	render() {
		return (
			<>
				<Button className="umk-slider-blog-button" onClick={this.props.onClick}>
					<LeftOutlined />
				</Button>
			</>
		);
	}
}

export default UmkBlogLeftButton;

import ApiRequestService from './ApiRequestService';

export default class UnimakeApiService {
    protected apiService: ApiRequestService;
    public endpoint: string;

    constructor() {           
        this.apiService = new ApiRequestService();
        this.endpoint = process.env.REACT_APP_API_ENDPOINT;        
    }

    public async get(path: string, headers: any = null): Promise<any> {
        return this.apiService.Get(`${this.endpoint}/${path}`, headers);
    }

    public async post(path: string, data?: any, headers?: any): Promise<any> {
        return this.apiService.Post(`${this.endpoint}/${path}`, data, headers);
    }

    public async put(path: string, data?: any, headers?: any): Promise<any> {
        return this.apiService.Put(`${this.endpoint}/${path}`, data, headers);
    }

    public async delete(path: string, data?: any, headers?: any): Promise<any> {
        return this.apiService.Delete(`${this.endpoint}/${path}`, data, headers);
    }
}
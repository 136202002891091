import { Component } from "react";
import ForbiddenPage from '../pages/ForbiddenPage';
import AuthenticationService from './AuthenticationService';


export default class MenuNavigation extends Component {

    /**
     * Abre uma página na navegação
     * @param page Página a abrir
     */
    public OpenPage(page: any, auth: boolean = false) {
        if(auth) {
            let auth = new AuthenticationService();
            let enabled = auth.CheckAuth(); 

            if(enabled)
                return(
                    page
                )
                else
                return (
                    ForbiddenPage
                )

        } else {
            return (
                page
            );
        }        
    }
}
import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	CloudDownloadOutlined,
	ClusterOutlined,
	CodeOutlined,
	MailOutlined,
	PrinterOutlined,
} from "@ant-design/icons";
import React from "react";
import { Row, Col, Table, Button } from "antd";
import ImageViewer from "react-simple-image-viewer";
import ContatoComponent from "./components/ContatoComponent";
import IResponse from "../../contract/IResponse";
import {updateQuantity} from "../../actions/CartActions";
import ProdutoService from "../../services/Loja/ProdutoService";
import ClienteService from "../../services/ClienteService";
import NotificationUtil from "../../utils/NotificationUtil";
import PedidoService from "../../services/Loja/PedidoService";
import ModalUltil from "../../utils/ModalUtil";
import CookieUtil from "../../utils/CookieUtil";
import {Redirect} from "react-router-dom";


const images = [require("../../assets/img/products/unidanfe_image_full.jpg")];
const dataSource = [
	{
		key: "1",
		carac: "Possibilita múltiplas configurações, permitindo maior flexibilidade na emissão do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "2",
		carac: "Visualização do DANFE em tela antes de imprimir, com a opção de abandonar sem imprimir",
		free: true,
		plus: true,
	},
	{
		key: "3",
		carac: "Impressão do DANFE de forma manual - após a visualização - ou automática, sem a intervenção do operador do sistema ERP",
		free: true,
		plus: true,
	},
	{
		key: "4",
		carac: "Impressão do DANFE em múltiplas vias (cópias)",
		free: true,
		plus: true,
	},
	{
		key: "5",
		carac: "Impressão do DANFE em formato retrato e paisagem",
		free: true,
		plus: true,
	},
	{
		key: "6",
		carac: "Impressão do DANFE em formulário de segurança (contingência)",
		free: true,
		plus: true,
	},
	{
		key: "7",
		carac: "Envio do arquivo XML por e-mail, como anexo (manual e automático)",
		free: true,
		plus: true,
	},
	{
		key: "8",
		carac: "Envio do DANFE por e-mail, como anexo (manual e automático)",
		free: true,
		plus: true,
	},
	{
		key: "9",
		carac: "Formato do DANFE enviado por e-mail",
		free: "DNF(1)",
		plus: "PDF",
	},
	{
		key: "10",
		carac: "Personalização da linha de detalhe dos produtos da NF-e, com a inclusão de qualquer informação presente no XML (2)",
		free: false,
		plus: true,
	},
	{
		key: "11",
		carac: "Impressão das informações de rastreamento dos produtos da NF-e",
		free: false,
		plus: true,
	},
	{
		key: "12",
		carac: "Salvar o DANFE em formato PDF para uma pasta local ou da rede",
		free: false,
		plus: true,
	},
	{
		key: "13",
		carac: "Salvar o arquivo XML para uma pasta local ou da rede",
		free: false,
		plus: true,
	},
	{
		key: "14",
		carac: "Upload do DANFE em formato PDF para um servidor FTP",
		free: false,
		plus: true,
	},
	{
		key: "15",
		carac: "Upload do arquivo XML para um servidor FTP",
		free: false,
		plus: true,
	},
	{
		key: "16",
		carac: "Geração, impressão e distribuição do DACTE com todas as características do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "17",
		carac: "Geração, impressão e distribuição do DAMDFE com todas as características do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "18",
		carac: "Geração, impressão e distribuição do CF-e-SAT com todas as características do DANFE",
		free: true,
		plus: true,
	},
	{
		key: "19",
		carac: "Suporte via chat on line",
		free: true,
		plus: true,
	},
];
const columns = [
	{
		title: "Características Principais",
		dataIndex: "carac",
		key: "carac",
	},
	{
		title: "FREE",
		dataIndex: "free",
		key: "free",
		render: (value) => {
			if (typeof value === "string") {
				return <p className="text-center">{value}</p>;
			} else {
				return value == true ? (
					<CheckCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#52c41a"
					/>
				) : (
					<CloseCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#eb2f96"
					/>
				);
			}
		},
	},
	{
		title: "PLUS",
		dataIndex: "plus",
		key: "plus",
		render: (value) => {
			if (typeof value === "string") {
				return <p className="text-center">{value}</p>;
			} else {
				return value == true ? (
					<CheckCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#52c41a"
					/>
				) : (
					<CloseCircleTwoTone
						style={{ fontSize: 24, display: "block", marginLeft: "auto", marginRight: "auto" }}
						twoToneColor="#eb2f96"
					/>
				);
			}
		},
	},
];
const unidanfeId = 3;

class UniDANFEPage extends React.Component<any, any> {
	private ProdutoService: ProdutoService;
	private ClienteService: ClienteService;
	private PedidoService: PedidoService;

	private NotificationUtil: NotificationUtil;
	private ModalUltil: ModalUltil;
	private CookieUtil: CookieUtil;

	state = {
		UniDanfeLogo: require("../../assets/img/products/danfe_icon.png"),
		loading: false,
		redirect: false,
		redirectPage: "",
		openedViewer: false,
		currentImage: 0,
		logoUnidanfe: require("../../assets/img/products/topo/unidanfe.png"),
	};

	constructor(props: any) {
		super(props);

		this.ProdutoService = new ProdutoService();
		this.ClienteService = new ClienteService();
		this.PedidoService = new PedidoService();

		this.NotificationUtil = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.CookieUtil = new CookieUtil();

		this.comprar = this.comprar.bind(this);
		this.openImageViewer = this.openImageViewer.bind(this);
		this.closeImageViewer = this.closeImageViewer.bind(this);
	}

	componentDidMount() {
		document.title = "Unimake Software - UniDANFE";
	}

	openImageViewer(index) {
		this.setState({
			openedViewer: true,
			currentImage: index,
		});
	}

	closeImageViewer() {
		this.setState({
			openedViewer: false,
			currentImage: 0,
		});
	}

	async comprar() {
		try {
			let cliente = this.ClienteService.GetClienteLogado();
			this.setState({ loading: true });

			this.PedidoService.Comprar(unidanfeId, 1, cliente ? cliente.idcliente : null)
				.then((result) => {
					let response: IResponse = result.data;

					//TODO: Verificar sobre o segundo pedido
					if (response.success) {
						let id = response.data.IDPedido;

						this.CookieUtil.Set("OrderID", id);
						localStorage.setItem("OrderID", id);

						updateQuantity(1);

						this.ModalUltil.Success("Produto adicionado ao carrinho!");

						this.setState({
							redirect: true,
							redirectPage: `${process.env.PUBLIC_URL}/loja/carrinho`,
						});
					} else {
						this.ModalUltil.Exception(
							response.message ? response.message : "Houve um erro ao adicionar o produto"
						);
					}

					this.setState({ loading: false });
				})
				.catch((err) => {
					this.setState({ loading: false });
					this.ModalUltil.Exception(err);
				});
		} catch (err) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(err);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<>
				<div className="topo-border-rounded color-unidanfe">
					<Row align="middle" justify="space-around">
						<Col
							xs={{ order: 2, span: 20 }}
							lg={{ order: 1, span: 10, offset: 2 }}
							xxl={{ order: 1, span: 7, offset: 5 }}>
							<h1 className="hidden-xs hidden-sm hidden-md">UniDANFE</h1>

							<p>Software para impressão e envio de XML's de Documentos Fiscais Eletrônicos</p>

							<p className="subtitulo">
								Imprima e dispare DANFE's e XML's de NF-e, <br />
								CT-e, CC-e e outros documentos de forma <br />
								automática para seus clientes.
							</p>
						</Col>
						<Col lg={{ order: 2, span: 10 }} xs={{ order: 1 }}>
							<img className="topo-produto" src={this.state.logoUnidanfe}></img>
						</Col>
					</Row>
				</div>

				<section>
					<div className="unimake-container">
						<div className="content-area-product">
							<div className="pre-description-center">
								<br />

								<p>
									O UniDANFe é um aplicativo que funciona integrado ao seu sistema de gestão. <br />{" "}
									Quando um Documento Fiscal Eletrônico é gerado ele faz o processo de impressão e
									disparo do XML para o destinatário final.
								</p>

								<br />
							</div>

							<div className="featuresBox-unidanfe">
								<Row justify="space-between">
									<Col sm={11} md={7}>
										<p style={{ fontSize: 19, color: "#034563" }}>
											<b>
												Como o UniDANFe simplifica sua vida na hora de emitir Notas Fiscais
												Eletrônicas
											</b>
										</p>
									</Col>

									<Col sm={11} md={7}>
										<PrinterOutlined style={{ color: "#ff5c5c" }} />
										<p>Impressão automática do DANFE, DACTE, CC-e após a geração do documento.</p>
									</Col>

									<Col sm={11} md={7}>
										<MailOutlined style={{ color: "#ff5c5c" }} />
										<p>Disparo automático do XML para o e-mail do cliente.</p>
									</Col>

									<Col sm={11} md={7}>
										<CodeOutlined style={{ color: "#ff5c5c" }} />
										<p>Integra-se a qualquer sistema de gestão do mercado.</p>
									</Col>

									<Col sm={11} md={7}>
										<ClusterOutlined style={{ color: "#ff5c5c" }} />
										<p>Customiza colunas do DANFE conforme a necessidade do emitente.</p>
									</Col>

									<Col sm={11} md={7}>
										<CloudDownloadOutlined style={{ color: "#ff5c5c" }} />
										<p>
											Baixe e use a versão FREE, sem cadastro, sem contrato! Obtenha recursos
											poderosos com a versão PLUS.
										</p>
									</Col>
								</Row>
							</div>

							<div className="imageMiddle-unidanfe">
								<Row justify="space-around">
									<Col sm={20} md={20}>
										<div>
											<p
												style={{
													textAlign: "center",
													fontSize: 25,
													fontWeight: 600,
													color: "#000000",
												}}>
												Simplifique o processo de impressão e envio de Nota Fiscal e outros
												Documentos Fiscais Eletrônicos para seus clientes.
											</p>

											<br />

											<p style={{ textAlign: "center", fontSize: 21, fontWeight: 400 }}>
												Quer imprimir e enviar os XML´s para seus clientes gratuitamente?
												Precisa de recursos mais avançados? Conheças as edições FREE e PLUS
											</p>
										</div>
									</Col>
								</Row>
							</div>

							<Table bordered={true} dataSource={dataSource} columns={columns} pagination={false} />

							<div className="comoFunciona-unidanfe">
								<Row>
									<span style={{ fontSize: 27, fontWeight: 600 }}>Como o Unidanfe funciona?</span>
									<br /> <br />
									<p style={{ fontSize: 18, fontWeight: 400 }}>
										O Unidanfe pode ser integrado pelo time de tecnologia (TI) da sua empresa a
										qualquer sistema de faturamento (ERP) do mercado, agindo como um componente
										auxiliar.
										<br />
										<br />
										Após configurado ele coleta automaticamente o XML enviado pelo sistema de
										faturamento já aprovado pela SEFAZ, imprime uma imagem do
										DANFE/DACTE/CC-E/DAMDFE em papel ao mesmo tempo em que dispara um e-mail com o
										XML e a com a imagem do documento auxiliar para o destinatário. <br /> <br />
										Solicite ao seu time de TI para que baixe o manual do aplicativo para entender
										como é o processo de integração do Unidanfe ao sistema da sua empresa. <br />
										<br />
										Seu sistema não envia XML para a SEFAZ? Não se preocupe, o Unidanfe pode ser
										integrado também ao aplicativo Uninfe, concluindo o ciclo. <br />
										<br />
									</p>
									<span style={{ fontSize: 18, fontWeight: 600 }}>
										Veja como funciona clicando no infográfico abaixo:
									</span>
								</Row>
								<br />
								<Row>
									<Col md={24} sm={24}>
										{/* <img  onClick={ () => this.openImageViewer() } style={{ maxWidth: '70%' }} src={} alt="" /> */}
										{images.map((src, index) => (
											<img
												src={src}
												onClick={() => this.openImageViewer(index)}
												width="300"
												key={index}
												style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
												alt=""
											/>
										))}
									</Col>
								</Row>
							</div>
						</div>
					</div>

					<Row align="middle" justify="space-around" className="color-black-transparent">
						<Col xs={22} lg={16}>
							<br />

							<p style={{ color: "white", fontSize: 24 }}>
								Faça o download do Unidanfe e revolucione a emissão de Documentos Fiscais Eletrônicos na
								sua empresa
							</p>

							<Row justify="start">
								<Col xs={12} md={12} lg={8}>
									<Button
										style={{ background: "white", color: "black", border: "none" }}
										shape="round"
										size="large"
										type="primary">
										<a href="https://conteudo.unimake.com.br/unidanfe" target="_blank" rel="noopener noreferrer">Instalar edição FREE</a>
									</Button>
								</Col>
								<Col xs={12} md={12} lg={8}>
									<Button
										style={{ background: "#006600", color: "white", border: "none" }}
										shape="round"
										size="large"
										loading={this.state.loading}
										onClick={() => {
											this.comprar();
										}}
										type="primary">
										Comprar a edição PLUS
									</Button>
								</Col>
							</Row>

							<br />
						</Col>

						{/* <Col sm={8} md={8} lg={8}>
									TODO: Precisa de uma imagem de um computador aqui com o unidanfe rodando
								</Col> */}
					</Row>

					<ContatoComponent />
				</section>

				{this.state.openedViewer && (
					<ImageViewer src={images} currentIndex={this.state.currentImage} onClose={this.closeImageViewer} />
				)}
			</>
		);
	}
}
export default UniDANFEPage;

import React from "react";
import { Menu, Button, Divider } from "antd";
import { Link } from "react-router-dom";

import StorageService from "../../services/StorageService";
import Store from "../../store/Store";
import { updateNameClient } from "../../actions/CartActions";
import CookieUtil from "./../../utils/CookieUtil";

import {
	ShoppingCartOutlined,
	DashboardOutlined,
	ProfileOutlined,
	LogoutOutlined,
	UserOutlined,
	UserAddOutlined,
	LockOutlined,
} from "@ant-design/icons";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class UmkRightNav extends React.Component<any, any> {
	static defaultProps = {
		Mode: "horizontal",
	};

	state = {
		Logged: false,
		UserName: "Visitante",
		CartQuantity: 0,
	};

	private Storage: StorageService;

	constructor(props: any) {
		super(props);

		this.handleLogout = this.handleLogout.bind(this);
		this.atualizarCarrinho = this.atualizarCarrinho.bind(this);
		this.atualizarNomeCliente = this.atualizarNomeCliente.bind(this);

		this.Storage = new StorageService();
	}

	componentDidMount() {
		Store.on("cartUpdated", this.atualizarCarrinho);
		Store.on("clientNameUpdated", this.atualizarNomeCliente);

		let user = this.Storage.Get("Usuario");

		if (user) {
			user = JSON.parse(user);
			this.setState({
				UserName: user.nome_fantasia,
				Logged: true,
			});
		}
	}

	componentWillUnmount() {
		Store.removeListener("cartUpdated", this.atualizarCarrinho);
		Store.removeListener("clientNameUpdated", this.atualizarNomeCliente);
	}

	atualizarCarrinho() {
		this.setState({
			CartQuantity: Store.getCartQuantity(),
		});
	}

	atualizarNomeCliente() {
		this.setState({
			UserName: Store.getClientName(),
			Logged: true,
		});
	}

	handleLogout() {
		updateNameClient("Visitante");

		this.setState({
			Logged: false,
		});

		let storage = new StorageService();
		let cookie = new CookieUtil();

		storage.Clear();

		cookie.Delete("TokenUsuario");

		window.location.href = "/cliente/login";
	}

	render() {
		return (
			<Menu className="menu-unimake-login" mode={this.props.Mode}>
				<Divider className="hidden-lg" />
				<Menu.Item key="cart">
					<Link className="welcome" to="/loja/carrinho">
						<ShoppingCartOutlined />
						{this.state.CartQuantity}{" "}
					</Link>
				</Menu.Item>
				{this.state.Logged ? (
					<SubMenu key="welcome" className="welcome" title={<UserOutlined style={{ color: "#44ff00" }} />}>
						<MenuItemGroup key="menu_welcome" className="menu-username" title={this.state.UserName}>
							<Menu.Item key="area">
								<Link to="/cliente/dashboard">
									{" "}
									<DashboardOutlined /> Área do Cliente
								</Link>
							</Menu.Item>
							<Menu.Item key="perfil">
								<Link to="/cliente/perfil">
									{" "}
									<ProfileOutlined /> Perfil
								</Link>
							</Menu.Item>
							<Menu.Item key="alterarsenha">
								<Link to="/cliente/alterar-senha-auth">
									{" "}
									<LockOutlined /> Alterar Senha
								</Link>
							</Menu.Item>
							<Menu.Item style={{ paddingBottom: 15, paddingTop: 15, height: "100%" }} key="cadastro">
								<Button className="btn-sair" onClick={this.handleLogout} block>
									<LogoutOutlined /> Sair
								</Button>
							</Menu.Item>
						</MenuItemGroup>
					</SubMenu>
				) : (
					<SubMenu key="acesso" title={<UserOutlined />}>
						<MenuItemGroup key="menu_acesso" title="Acesso">
							<Menu.Item key="login">
								<Link to="/cliente/login">
									{" "}
									<UserOutlined />
									Login
								</Link>
							</Menu.Item>
							<Menu.Item key="cadastro">
								<Link to="/cliente/cadastro">
									{" "}
									<UserAddOutlined /> Cadastro
								</Link>
							</Menu.Item>
						</MenuItemGroup>
					</SubMenu>
				)}
			</Menu>
		);
	}
}
export default UmkRightNav;

import React from 'react';
import {Card, Divider, Typography, Row, Col, Button} from 'antd';
import ClienteService from './../../services/ClienteService';
import ICliente from './../../contract/cliente/ICliente';
import CommonUtil from './../../utils/CommonUtil';
import ModalUltil from './../../utils/ModalUtil';
import {Redirect} from 'react-router-dom';

const {Title, Paragraph} = Typography;

class FecharCompraPage extends React.Component<any, any> {
    private ClienteService: ClienteService;
    private CommonUtil: CommonUtil;
    private ModalUltil: ModalUltil;

    state = {
        cliente: undefined,
        orderid: undefined,
        redirect: false,
        redirectUrl: ''
    }

    constructor(props: any) {
        super(props);

        this.ClienteService = new ClienteService();
        this.CommonUtil = new CommonUtil();
        this.ModalUltil = new ModalUltil(props);

        this.initialize = this.initialize.bind(this);
        this.loadCliente = this.loadCliente.bind(this);
    }

    componentDidMount() {
        document.title = "Unimake Software - Finalizar Compra";
        this.initialize();
    }

    initialize() {
        this.loadCliente();
        let order = localStorage.getItem('orderfinale');
        // localStorage.removeItem('orderfinale');

        if (!order) {
            this.ModalUltil.Error("Pedido não encontrado");

            this.setState({
                redirect: true,
                redirectUrl: `${process.env.PUBLIC_URL}/produtos`
            });

            return;
        }

        order = this.CommonUtil.toMD5(order);

        this.setState({
            orderid: order
        });
    }

    loadCliente() {
        let cliente = this.ClienteService.GetClienteLogado();

        this.setState({
            cliente: cliente
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectUrl}/>;
        }
        let url = '';
        let url_pagseguro = '';

        let cliente: ICliente = this.state.cliente;

        if (cliente) {
            let idpedido = this.state.orderid;
            let idcliente = this.CommonUtil.toMD5(cliente?.idcliente.toString());

            url = `http://www.unimake.com.br/loja/boleto.php?a=${idpedido}&b=${idcliente}`;
            url_pagseguro = `http://www.unimake.com.br/loja/pagseguro.php?a=${idpedido}&b=${idcliente}&f=2`;
        }

        return (
            <section className="unimake-container">
                <Card className="content-area">
                    <Title level={3}>Compra Concluída - Escolha a forma de pagamento</Title>
                    <Divider/>

                    {/* TODO: Como identificar a filial? */}
                    {/* TODO: Estes dados devem ser mais faceis de ser alterados, talvez via arquivo ou API */}
                    <Card title="Vendedor">
                        {
                            cliente?.idcliente === 7454 ? (
                                    <div>
                                        <span>LW Softwares LTDA - EPP</span> <br/>
                                        <span>Avenida Comendador Norberto Marcondes, 2156 - Centro</span> <br/>
                                        <span>87303-100 - Campo Mourão - PR</span> <br/>
                                        <span>Fone: (44) 3017-1247 - CNPJ: 01.761.135/0001-32 - Inscrição Estadual: 902.19870-90</span>
                                        <br/>
                                    </div>
                                ) :
                                (
                                    <div>
                                        <span>Unimake Soluções Corporativas Ltda</span> <br/>
                                        <span>Rua Paulo Antônio da Costa, 575, Jardim Simara</span> <br/>
                                        <span>87707-210 - Paranavaí - PR</span> <br/>
                                        <span>Fone: (44) 3142-1010 - CNPJ: 06.117.473/0001-50 - Inscrição Estadual: 903.20003-01</span>
                                        <br/>
                                    </div>
                                )
                        }
                    </Card>

                    <br/>

                    <Card title="Opções de Pagamento">
                        <Row>
                            <Col className="center" xs={24} sm={10} md={10}>
                                <Button href={url} target="_blank" size="large" type="primary" block>Gerar
                                    Boleto</Button>
                            </Col>

                            <Col sm={1} md={1}/>

                            <Col className="center" xs={24} sm={10} md={10}>
                                <Button href={url_pagseguro} target="_blank" size="large" type="primary" block>Pagar com
                                    Cartão ou TEF</Button>
                            </Col>
                        </Row>
                    </Card>

                    <br/>

                    <Card title="Gerenciamento de Licenças">
                        <Paragraph>
                            A licença adquirida foi creditada em sua conta e você já pode utilizá-la: entre no
                            aplicativo; acesse a opção "Licenças" no menu; clique no botão "Download"; digite a chave da
                            licença na tela "Download de licença".
                            Se o pagamento da compra não estiver compensando no momento do download será gerada uma
                            licença temporária com validade de alguns dias para você utilizar o sistema até que a
                            compensação seja realizada.
                        </Paragraph>
                    </Card>

                    <br/>

                    <Card title="Observações">
                        <Paragraph>
                            <ul>
                                <li>1) A NF-e deste pedido será enviada por e-mail, bem como disponibilizada para
                                    download na Área do Cliente, em até 05 dias após a confirmação do pagamento.
                                </li>
                                <li>2) As licenças efetivas serão geradas imediatamente após a confirmação do
                                    pagamento.
                                </li>
                            </ul>
                        </Paragraph>

                        <Paragraph strong={true} style={{color: 'red'}}>
                            NOTA: Não é necessário nos enviar o comprovante do pagamento. Nosso sistema efetua as baixas
                            automaticamente através do arquivo de retorno do banco ou do PagSeguro.
                        </Paragraph>
                    </Card>

                    <br/>

                </Card>
            </section>
        );
    }
}

export default FecharCompraPage;

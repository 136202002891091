import { Modal, Result } from "antd";
import React from "react";

export default class ModalUltil extends React.Component<any, any> {
	public Error(message: string) {
		Modal.error({
			content: <Result status="error" title="Ops!" subTitle={message} />,
		});
	}

	public Exception(exception: any) {
		console.log(exception);

		let message: string;

		if (exception.message) message = exception.message;
		else message = exception;

		Modal.error({
			content: <Result status="error" title="Ops" subTitle={message} />,
		});
	}

	public Success(message: string) {
		Modal.success({
			content: <Result status="success" title="Ok!" subTitle={message} />,
		});
	}

	public SuccessCustomTitle(title: string, message: string) {
		title = !title ? "Ok!" : title;
		Modal.success({
			content: <Result status="success" title={title} subTitle={message} />,
		});
	}
}

import React from "react";
import UmkDownload from "../../components/unimake-download/UmkDownload";
import { Col, Row } from "antd";
import Title from "antd/lib/typography/Title";

class DownloadsPage extends React.Component<any, any> {
	state = {
		loading: true,
	};

	componentDidMount() {
		document.title = "Unimake Software - Downloads";

		setTimeout(() => {
			this.setState({ loading: false });
		}, 500);
	}

	render() {
		return (
			<section className="unimake-container">
				<Title level={1}>Downloads</Title>

				<UmkDownload Titulo="UniNFe" Categoria="uninfe" Key="1" ActiveKey="1" documentacao="http://wiki.unimake.com.br/index.php/Manuais:UniNFe"  historicoVersao="http://redmine.unimake.com.br/projects/uninfe/wiki/BuildsUniNFe"/>
				<UmkDownload Titulo="UniDANFE" Categoria="unidanfe" Key="2" ActiveKey="2" documentacao="http://wiki.unimake.com.br/index.php/Manuais:UniDANFE"  historicoVersao="http://wiki.unimake.com.br/index.php/Manuais:UniDANFE/Builds" />
				<UmkDownload Titulo="DanfeVIEW" Categoria="danfeview" Key="3" ActiveKey="3" historicoVersao="http://wiki.unimake.com.br/index.php/Manuais:DANFEView/Builds"/>
				<UmkDownload Titulo="GEMINI - Cloud Backup" Categoria="gemini" Key="4" ActiveKey="4" />
				<UmkDownload Titulo="Único" Categoria="unico" Key="5" ActiveKey="5" />
				<UmkDownload Titulo="Outros" Categoria="outros" Key="6" ActiveKey="6" />
			</section>
		);
	}
}
export default DownloadsPage;

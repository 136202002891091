import React from "react";
import CookieUtil from "./../../utils/CookieUtil";
import { Redirect } from "react-router-dom";
import UmkClienteProfile from "./../../components/cliente/UmkClienteProfile";

class ProfileClientePage extends React.Component<any, any> {
	private CookieUtil: CookieUtil;

	state = {
		redirect: false,
		redirectPage: "",
	};

	constructor(props: any) {
		super(props);

		this.CookieUtil = new CookieUtil();
		this.checkLogin = this.checkLogin.bind(this);
	}

	componentDidMount() {
		document.title = "Unimake Software - Perfil";

		this.checkLogin();
	}

	async checkLogin() {
		let token = await this.CookieUtil.Get("TokenUsuario");

		if (!token || token === "undefined") {
			localStorage.setItem("redirect", "true");
			localStorage.setItem("redirectPath", window.location.pathname);

			this.setState({
				redirect: true,
				redirectPage: `${process.env.PUBLIC_URL}/cliente/login`,
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectPage} />;
		}

		return (
			<>
				<section className="unimake-container">
					<UmkClienteProfile />
				</section>
			</>
		);
	}
}
export default ProfileClientePage;

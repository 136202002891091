"use strict";

import React from "react";
import { Row, Col } from "antd";
import "./HomePage.scss";

import UmkSlider from "../../components/unimake-slider/UmkSlider";

import produtos from "../../variables/produtos";
import UmkProdutoSlider from "../../components/v3/unimake-produtos-slider/UmkProdutosSlider";
import UmkBlogNews from "../../components/v3/unimake-blog-news/UmkBlogNews";

class HomePage extends React.Component<any, any> {
	state = {
		SlideItens: [
			{
				image: require("../../assets/img/banners/banner_01.png"),
			},
			{
				image: require("../../assets/img/banners/banner_02.png"),
			},
			{
				image: require("../../assets/img/banners/banner_03.png"),
			},
			{
				image: require("../../assets/img/banners/banner_04.png"),
			},
			{
				image: require("../../assets/img/banners/banner_05.png"),
			},
		],
		SlideItensSmartphone: [
			{
				image: require("../../assets/img/banners/banner_1-mobile.jpg"),
			},
			{
				image: require("../../assets/img/banners/banner_2-mobile.jpg"),
			},
			{
				image: require("../../assets/img/banners/banner_3-mobile.jpg"),
			},
			{
				image: require("../../assets/img/banners/banner_4-mobile.jpg"),
			},
		],
		Produtos: produtos,
	};

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		document.title = "Unimake Software - Home";
	}

	render() {
		return (
			<div>
				<section className="top-slider hidden-xs hidden-sm">
					<UmkSlider className="principal-slider" SlideItens={this.state.SlideItens} speed={7000} />
				</section>
				<section className="top-slider hidden-md hidden-lg">
					<UmkSlider className="principal-slider" SlideItens={this.state.SlideItensSmartphone} speed={7000} />
				</section>

				<section className="product-home-areav3">
					<div className="unimake-container-home">
						<Row>
							<Col sm={24} md={24}>
								<UmkProdutoSlider
									Produtos={this.state.Produtos}
									style={{ paddingTop: 55, paddingBottom: 55 }}
								/>
							</Col>
						</Row>
					</div>
				</section>
				<section className="unimake-area-v3">
					<div className="unimake-container-home">
						<Row>
							<Col sm={24} md={24}>
								<UmkBlogNews Count={5} />
							</Col>
						</Row>
					</div>
				</section>
			</div>
		);
	}
}
export default HomePage;

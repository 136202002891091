import React from "react";
import { Row, Col, Card, Typography } from "antd";
import UmkContato from "./../../components/forms/unimake-contato/UmkContato";
import UmkSobreMap from "../../components/maps/UmkSobreMap";

let { Title } = Typography;

class ContatoPage extends React.Component<any, any> {
	componentDidMount() {
		document.title = "Unimake Software - Contato";
	}

	render() {
		return (
			<section className="unimake-container">
				<Title level={1}>Contato</Title>

				<Row>
					<Col sm={14} md={14}>
						<Card title="Contato">
							<UmkContato />
						</Card>
					</Col>

					<Col sm={1} md={1} />

					<Col sm={9} md={9}>
						<Card title="Endereço">
							<Row style={{ fontSize: "16px" }}>
								<Col sm={12} md={12}>
									<span style={{ fontWeight: "bold" }}>Campo Mourão</span> <br />
									<span>Av. Com. Norberto Marcondes, 2156, CEP: 87303-100</span>
								</Col>

								<Col sm={12} md={12}>
									<span style={{ fontWeight: "bold" }}>Paranavaí</span> <br />
									<span>Rua Paulo Antônio da Costa, 575, Jardim Simara, CEP: 87707-210</span>
								</Col>

								<br />
							</Row>

							<Row>
								<div
									style={{
										marginTop: 15,
										marginBottom: 15,
										marginLeft: "auto",
										marginRight: "auto",
										display: "block",
									}}>
									<a
										href="tel:(44) 3141-4900"
										style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
										Telefone: (44) 3142-1010
									</a>
								</div>
							</Row>

							<Row>
								<Col lg={24} sm={24} md={24} xs={24}>
									<UmkSobreMap />
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</section>
		);
	}
}
export default ContatoPage;
